import React,{useEffect, useState} from 'react';
import { Header } from '../components/Header';
import '../assets/css/styles.css';
import '../assets/css/components/optionMenu.css';
import {  dataMenu} from '../actions/actionMenus';
import { OptionMenu } from '../components/OptionMenu.jsx';
export const MainMenu = () => {
	
	const [data, setData] = useState([]);
	
	//1000 * 60 * 60 * 24

	useEffect(() => {
	     //cargar lo que viene en dataPriming async
		 async function fetchData() {
			const data = await dataMenu();
			setData(data);
			//await GuardarDataSubMenu();

			
			
		  }
		
		  fetchData();
		  return ()=>{
			  setData();
		  }

	} , [])

	return (
		<div className="container-fluid p-0 ">
			<Header text="iHEALTHe Journey" />
			<div className="bg-white border-top-radius position-relative nTop-1 w-100 p-3 ">
				<div className="d-flex  justify-content-evenly flex-wrap mb-5  mb-5 position-relative justify-content-lg-around">
					{data[0]&&(
						<OptionMenu text={data[0].option} image={data[0].image} type={data[0].type} id={data[0].MenuOptionId} url={data[0].url}  active={data[0].active} />
					)}
					{data[1]&& (
						<OptionMenu
							text={data[1].option}
							model="2"
							image={data[1].image}
							type={data[1].type}
							id={data[1].MenuOptionId}
							url={data[1].url}
							
						active={data[1].active} />
					)}
					{data[2]&& (
						<OptionMenu
							text={data[2].option}
							model="2"
							image={data[2].image}
							type={data[2].type}
							id={data[2].MenuOptionId}
							url={data[2].url}
						active={data[2].active} />
					)}
					{data[3]&& (
						<OptionMenu
							text={data[3].option}
							model="1"
							image={data[3].image}
							type={data[3].type}
							id={data[3].MenuOptionId}
							url={data[3].url}
						active={data[3].active} />
					)}
					{data[4]&& (
						<OptionMenu text={data[4].option} url={data[4].url} image={data[4].image} id={data[4].MenuOptionId} active={data[4].active} />
					)}
					{data[5]&& (
						<OptionMenu
							text={data[5].option}
							model="2"
							image={data[5].image}
							type={data[5].type}
							id={data[5].MenuOptionId}
							url={data[5].url}
						active={data[5].active} />
					)}
					{data[6]&& (
						<OptionMenu
							text={data[6].option}
							model="3"
							image={data[6].image}
							type={data[6].type}
							id={data[6].MenuOptionId}
							url={data[6].url}
						active={data[6].active} />
					)}
					{data[7]&& (
						<OptionMenu
							text={data[7].option}
							model="1"
							image={data[7].image}
							type={data[7].type}
							id={data[7].MenuOptionId}
							url={data[7].url}
						active={data[7].active} />
					)}
					{data[8]&& (
						<OptionMenu
							text={data[8].option}
							model="2"
							image={data[8].image}
							type={data[8].type}
							id={data[8].MenuOptionId}
							url={data[8].url}
						active={data[8].active} />
					)}
					{data[9]&& (
						<OptionMenu
							text={data[9].option}
							model="2"
							image={data[9].image}
							type={data[9].type}
							id={data[9].MenuOptionId}
							url={data[9].url}
						active={data[9].active} />
					)}
					{data[10]&& (
						<OptionMenu text={data[10].option} url={data[10].url} model="1" image={data[10].image} id={data[10].MenuOptionId} active={data[10].active} />
					)}

					{/* <OptionMenu text="OUR VIDE" type="2" image={data[0].image} />
					<OptionMenu text="OUR VIDE" imagen={data[0].image} />
					<OptionMenu text="OUR VIDE" type="1" imagen={data[0].image} />
					<OptionMenu text="OUR VIDE" type="2" imagen={data[0].image} />
					<OptionMenu text="OUR VIDE" type="3" imagen={data[0].image} /> */}
				</div>
			</div>
		</div>
	);
};
