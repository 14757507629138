//importar firebase
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { getFirestore, collection, addDoc, doc, getDocs } from 'firebase/firestore';
import { getMessaging, getToken } from "firebase/messaging";



const firebaseConfig = {
  apiKey: "AIzaSyBqdvF91x3aIPjf_jIldi667LWJk3c9mv8",
  authDomain: "ihealthe-app.firebaseapp.com",
  databaseURL: "https://ihealthe-app-default-rtdb.firebaseio.com",
  projectId: "ihealthe-app",
  storageBucket: "ihealthe-app.appspot.com",
  messagingSenderId: "976034627026",
  appId: "1:976034627026:web:29672b4d89769fe3829c0b",
  measurementId: "G-9KMVLKMVXW"
};

const claveMessaging = "BIGnoMhUAuVDvPx5bw4IbAvxKRg2idycj-XjPKhH1SJgkwe8Vi4kTy7uevP-hxu-OwHpZPZxF1UtbzVPezMENFw";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GoogleAuthProvider();
const providerFacebook = new FacebookAuthProvider();
const messaging = getMessaging(app);



const token=getToken(messaging, { vapidKey: claveMessaging }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log(currentToken);
    return currentToken;
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    return new Error ('error al obtener token');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});




export { app,auth, db, createUserWithEmailAndPassword, signInWithEmailAndPassword, collection, addDoc, doc, provider, signInWithPopup, GoogleAuthProvider, getDocs, providerFacebook, FacebookAuthProvider, messaging, claveMessaging, token };