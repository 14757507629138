import { types } from "../types/types";
import { dataSubMenu } from "./actionMenus";

//Ejemplo para estados asincronos
export const getSubMenuArray = (id, Title) => {

    return async (dispatch) => {
            const data = await dataSubMenu();
            dispatch(getSubMenu(id, Title, data));
        
    }

}

//Ejemplo para estados sincronos
export const getSubMenu = (id, title, objSubMenu) => {
    return {
        type: types.subMenu,
        payload: {
            Title: title,
            data: objSubMenu?.filter((sub) => sub.parentId === id)
        },
    };

}