import React from 'react';

export const MeasurementsInputs = ({ name1, value1, name2, value2, handleInputChange }) => {
	return (
		<div>
			<div className="d-flex justify-content-between">
				<div className=" d-flex flex-column col-5">
					{name1}
					<input
						type="number"
						className="form-control borderRound shadow"
						placeholder={name1}
						name={name1}
						value={value1}
						onChange={handleInputChange}
					/>
				</div>
				<div className=" d-flex flex-column col-5">
					{name2}
					<input
						type="number"
						className="form-control borderRound shadow"
						placeholder={name2}
						name={name2}
						value={value2}
						onChange={handleInputChange}
					/>
				</div>
			</div>
		</div>
	);
};
