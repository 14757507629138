import React ,{useEffect, useState}from 'react'
import { Line } from 'react-chartjs-2'
import '../assets/css/components/chartDashboard.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from 'axios';
export const ChartDashboard = () => {
const newData=[]
  const [state, setstate] = useState([])
  state.forEach(s=>{ newData.push(s.weight)})


    ChartJS.defaults.scale.display=false
    ChartJS.defaults.backgroundColor="red"
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      
    );


const options = {
    responsive: true,
    
    plugins: {
      legend: {
       display:false
      },
      title: {
        display: true,
        
      },
     
      
      scales: {
        xAxes: [
          {
            gridLines: {
              display: true,
              drawBorder: false,
              borderDash: [2, 2],
              zeroLineColor: "blue"
            },
            categoryPercentage: 0.7,
            barPercentage: 0.9,
            ticks: {
              beginAtZero: true
            }
          }
        ],
        yAxes: [
          {
            display: false,
            gridLines: {
              display: false,
              zeroLineColor: "transparent"
            },
            ticks: {
              beginAtZero: true
            }
          }
        ]
      }
      
    },
  };
  

const labels = ["day 1","day 2","day 3","day 4","day 5","day 6","day 7","day 8","day 9","day 10","day 11","day 12","day 13","day 14","day 15","day 16","day 17","day 18","day 19","day 20","day 21","day 22","day 23","day 24","day 25","day 26","day 27","day 28","day 29","day 30","day 31"];
const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        borderWidth:2,
        maintainAspectRatio: false ,
        data:newData,
        borderColor: 'white',
        backgroundColor: 'white',
        // backgroundColor: '#8bd600',
      },
      
     
    ],
  };

  const getData= async()=>{
    const api="https://myjson.dit.upm.es/api/bins/9y1h";
    const {data}=await axios.get(api);

return data;
   
   }


   useEffect(() => {
    getData().then(data=>{
      setstate(data)
      
    })
  
   
  
   
  }, [])
  

    return (
        <div>
        
             <div className=' position-relative col-11 col-lg-8 col-xl-4 container bg-chart p-3 mt-2'>
      <h2 className='text-center text-white fs-1 '>{Math.min(...newData)} KG</h2>
      <Line options={options} data={data}  />
     
    </div>
        </div>
    )
}
