import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../components/Header";
import { PreviousScreen } from "../components/PreviousScreen";
import { useForm } from "../hooks/useForm";
import { reauthenticateWithcredetials } from "../actions/actionUpdatePassword";
import { useAlert, positions } from "react-alert";
const initialState = {
  password: "",
  newPassword: "",
  newPassword2: "",
};
export const ChangePassword = ({path="/settings"}) => {
  const { values, handleInputChange, reset } = useForm(initialState);
const resetForm= useCallback(
  () => {
    reset()
  },
  [reset],
)
  const alert = useAlert();

  const [success, setsuccess] = useState(false);
  const [statusError, setstatusError] = useState({
    error: false,
    msj: "",
  });


  useEffect(() => {
    let mounted=true;
    if (success) {
      alert.show("Success", {
        type: "success",
        timeout: 5000,
        positions: positions.TOP_RIGHT,
      });
      if(mounted){
        resetForm()
      }

      setsuccess(false);
      
    }
    return () => {
      setsuccess()
      mounted=false;

    }
  }, [success,alert,resetForm])

  useEffect(() => {

    let mounted=true;
    if (statusError.error) {
      alert.show(statusError.msj, {
        type: "error",
        timeout: 5000,
        positions: positions.TOP_RIGHT,
      });

      


      setTimeout(() => {
        if(mounted){
          setstatusError({
            error: false,
            msj: "",
          });
        }
      }, 5000);
    }
    return () => {
      mounted=false;
      alert.removeAll()
    }
   
    
  }, [statusError,alert ]);

  const validatePasswords = () => {
    const strongPassword = values.newPassword.length >= 6;
    if (strongPassword) {
      if (values.newPassword !== values.newPassword2) {
        setstatusError({
          error: true,
          msj: "The new passwords do not match",
        });
        return false;
      }

      if (values.newPassword === values.password) {
        setstatusError({
          error: true,
          msj: "The new password cannot be the same as the old one",
        });
        return false;
      }
      return true;
    } else {
      setstatusError({
        error: true,
        msj: "The new password must be at least 6 characters",
      });

      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validatePasswords()) {
      await reauthenticateWithcredetials(
        values.password,
        values.newPassword,
        setstatusError,
        setsuccess
      );
    }

    
    if (statusError.error) {
      reset();
    }
  };

  return (
    <div>
      <Header lateralMenu />
      <PreviousScreen path={path} />

      <h1 className="text-center mt-1">Change password</h1>
      <form
        onSubmit={handleSubmit}
        className="mt-5 d-flex flex-column justify-content-center align-items-center"
      >
        <div className="col-10 col-lg-8 col-xl-4">
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              value={values.password}
              name="password"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              New password
            </label>
            <input
              type="password"
              className="form-control"
              value={values.newPassword}
              name="newPassword"
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Confirm new password
            </label>
            <input
              type="password"
              className="form-control"
              value={values.newPassword2}
              name="newPassword2"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn colorPrimario text-white shadow rounded borderRound mt-3"
          disabled={statusError.error}
        >
          <span className="mx-2">UPDATE</span>
        </button>
      </form>
    </div>
  );
};
