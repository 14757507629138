import React from 'react';

import '../assets/css/components/button.css';
export const Button = ({ text = '', colour = 'colorNaranja', funcionx = () => {} }) => {
	return (
		<div>
			<button
				onClick={funcionx}
				className={`btn  btnTitulo btnRound  mb-2  text-white w-40 text-center p-3 ${colour}`}
			>
				{text}
			</button>
		</div>
	);
};
