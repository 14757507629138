import React from 'react';
import '../assets/css/components/inputShadow.css';
export const InputShadow = ({
	icon = '',
	placeholder = '',
	name = '',
	value = '',
	handleInputChange = () => {},
	type = 'text'
}) => {
	return (
		<div className="d-flex flex-nowrap inputShadow border-0  p-2 position-relative ">
			<span className="input-group-text bg-transparent border-0 ">
				<i className={` ${icon}  text-secondary `} />
			</span>

			<input
				className=" h-75 py-2 inputShadow form-control shadow-none  w-100 border-round border-0  "
				type={type}
				value={value}
				onChange={handleInputChange}
				name={name}
				placeholder={placeholder}
				required
			/>
		</div>
	);
};
