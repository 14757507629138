import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Login } from '../views/Login';
import { Register } from '../views/Register';
import Home from '../views/Home';
import { useAlert, positions } from 'react-alert';
import { MainMenu } from '../views/MainMenu';
import { SubMenus } from '../views/SubMenus';
import { DashBoard } from '../views/DashBoard';
import FAQS from '../views/FAQS';
import { AccountSettings } from '../views/AccountSettings';
import { MyProfile } from '../views/MyProfile';
import {Card} from '../views/Card';
import { messaging } from '../utils/configFirebase';
import {onMessage} from 'firebase/messaging';
import { Settings } from '../views/Settings';
import { HealthEducation } from '../views/HealthEducation';
import { Test } from '../views/test';
import {ChangePassword} from '../views/ChangePassword';





export const AppRouter = () => {

	const alert = useAlert();

	onMessage(messaging, function(payload) {
		console.log('Message received. ', payload);

		alert.show(payload.notification.body, {
			positions: positions.TOP_RIGHT,
			type: 'success',
			timeout: 5000
		});
	  });

	return (
		
			<Router>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route exact path="/register" component={Register} />
					<Route exact path="/MainMenu" component={MainMenu} />
					<Route exact path="/home" component={Home} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/subMenu" component={SubMenus} />
					<Route exact path="/dashboard" component={DashBoard} />
					<Route exact path="/faqs" component={FAQS} />
					<Route exact path="/accountSettings" component={AccountSettings} />
					<Route exact path="/myProfile" component={MyProfile} />
					<Route exact path="/healtheEducation" component={HealthEducation} />
					<Route exact path="/settings" component={Settings} />
					<Route exact path="/card" component={Card} />
					<Route exact path="/test" component={Test} />
					<Route exact path="/changePassword"   render={(props) => <ChangePassword path="/subMenu" {...props} />}   />

					

					<Redirect to="/" />
				</Switch>
			</Router>
		
	);
};
