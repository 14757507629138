import axios from 'axios';


export const sendPicture = async (data)=>{
    try{
        const res = await axios.post('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/addPicture',data);
        return await res.data;
    }  catch(error){
        console.log(error);
        return [];
    }
}