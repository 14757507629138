import axios from 'axios';
export const getKeyPoints= async (day,phase)=>{


    try{
        const res = await axios.get('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/getKeyPoints');
            const keyPoints= await res.data.KeyPoints;
            return   keyPoints.filter(r=>r.day===day&&r.phase===phase);
        }  catch(error){
            console.log(error);
            return [];
        }
        
}