import React, { useCallback, useEffect, useState } from "react";
import { Header } from "../components/Header";
import { PreviousScreen } from "../components/PreviousScreen";
import "../assets/css/components/healthEducation.css";
import { PlayModalVideo } from "../components/PlayModalVideo";

import { getVideosHealtheEducation } from "../actions/actionHealtheEducation";

export const HealthEducation = () => {
   
const [videos, setvideos] = useState([])



     const getVideos =useCallback(
        async ()=>{
            try{
            const res = await getVideosHealtheEducation();
                return await res;
            }  catch(error){
                console.log(error);
                return [];
            }
        }
        ,
         [],
     )

     useEffect(  () => {
      const setVid=async ()=>{
        const data= await getVideos();
       
         setvideos(data);
      }
      setVid();

      return ()=>{
        setvideos();
        
      }
     }, [getVideos])
    
  
  
    return (
    <div>
      <Header />
      <PreviousScreen path={"/mainMenu"} />
      <div className="container ">
        <div className="health_education_container_title">
          <h1 className="text-center">iHEALTHe Innovate</h1>
          <h2 className="text-center h1 mb-4">HEALTH education</h2>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="h2 text-center fw-light m">
          Learn about your body <br /> with CWO Fadia Habib
        </h3>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center mt-4">
        
        {
            videos.length>0 &&videos.map((v,i)=>
               
            <PlayModalVideo key={i}  text={v.name} video={v.video}/>

              
            )
            
            
        }
      
      </div>
    </div>
  );
};
