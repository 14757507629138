import { AppRouter } from "./routers/AppRouter";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AlertTemplate from 'react-alert-template-basic';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';



const Ihealthe = () => {


	const options = {
		position: positions.TOP_RIGHT,
		timeout: 1500,
		offset: '30px',
		transition: transitions.SCALE
	};

  

  return (

    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <AppRouter />
      </AlertProvider>

    </Provider>
  );
}

export default Ihealthe;
