import {app} from './configFirebase'; 
import { uploadBytes, getDownloadURL,ref,getStorage} from "firebase/storage";
//impott put

//subir archivo a firebase
export const uploadFile = async (file, name) => {
  const getStorageRef =  getStorage(app);
  const flRef=  ref(getStorageRef, `Photos/${name}`);
  //const getMeta= getMetadata(flRef);
  await uploadBytes(flRef, file);
  

  const url = await getDownloadURL(flRef);
 
  return url;

}

