import React, { useState, useEffect } from "react";
import uploadIcon from "../assets/img/icons/upload.png";
import { useForm } from "../hooks/useForm";
import { isEmpty } from "validator";
import { HexagonText } from "./HexagonText";
import { MeasurementsInputs } from "./MeasurementsInputs";
import { sendPicture } from "../actions/actionPictures";
import { uploadFile } from "../utils/uploadWithFirebase";
import { getChallenges } from '../actions/actionChallenges';

//css
import "../assets/css/components/primingPhase.css";
import moment from "moment";
import { getNameUser } from "../helpers/getNameUser";

export const FinalRealisingPhase = ({ setmodal, date }) => {

  const year = moment(date).format("YYYY");
  const numberDay = moment(date).format("Do").replace("th", "");
  const month = moment(date).format("MMMM").substring(0, 3);
  const {name,lastName}=getNameUser()
  

  const [challeges, setChalleges] = useState([]);
  const [off_scale, setOff_scale] = useState([]);
  
  
  useEffect(() => {
    const userId=JSON.parse(localStorage.getItem('userSession')).userId;

    async function fetchData() {
      const data = await getChallenges({"userId":userId});
      setChalleges(data);

      const obj= data.map(item => {
        return item.off_scale_victory;
      } 
      );
      setOff_scale(obj);
    }
    
    fetchData();
  }, []);

  const updateOffScale = (index, e) => {
    const newOffScale = [...off_scale];
    newOffScale[index] = e.target.value;
    setOff_scale(newOffScale);
    console.log(newOffScale);
  };

  const uploadImageView = async (NameIma, modo) => {
    try {

      const image = document.getElementById(NameIma).files[0];
      const imageName = image.name;
      const converterBlobImage = new File([image], imageName, {
        type: image.type,
      });

      const imageUrl = await uploadFile(converterBlobImage, imageName);

      await sendPictureView(imageUrl, modo);

      setcounter(counter + 1);
    } catch (e) {


    }
  };

  const sendPictureView = async (url, modo) => {
    const dataPircture = {
      photo: url,
      mode: modo,
      phase: "Final Realising Phase",
      userId: getUserLocalStorage(),
      date: new Date().toISOString().slice(0, 10),
    };
    let res = await sendPicture(dataPircture);
    console.log(res);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setmodal(false);

    console.log(values);
  };

  const [unit, setUnit] = useState("inches");

  const initialState = {
    Thigh: "",
    Calf: "",
    Chest: "",
    Bicep: "",
    Neck: "",
    Shoulder: "",
    EndingtWeight: "",
    Waist: "",
    Hips: "",
    Unit: unit
  };

  const [counter, setcounter] = useState(1);
  const { values, handleInputChange } = useForm(initialState);

  const day = 31;

  const validateFieldsList = (str1, str2) => {
    let regexp = /^\s*$/;
    let result1 = regexp.test(str1);
    let result2 = regexp.test(str2);
    let resp = false;
    if (result1 || result2) {
      resp = true;
    }

    return !resp;
  };

  const handleChangeUnit = (e) => {
    setUnit(e.target.value);
  };

  function getUserLocalStorage() {
    let user = JSON.parse(localStorage.getItem("userSession"));
    return user.userId;
  }

 

  const renderSwitch = (counter) => {
    switch (counter) {
      case 1: {
        return (
          <div>
            <p>Ending Weight (kg) :</p>
            <div className="">
              <input
                name="EndingtWeight"
                value={values.EndingtWeight}
                onChange={handleInputChange}
                type="number"
                className="form-control borderRound shadow"
                placeholder="Ending Weight"
              />
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (!isEmpty(values.EndingtWeight)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 2: {
        return (
          <div>
            <div>
              <p>Ending Measurements :</p>
            </div>
            <div>
              <div>
                <p className="fs-6">Choose the unit of measure to use :</p>
              </div>
              <select
                onChange={handleChangeUnit}
                className="form-select borderRound"
                aria-label="Default select example"
              >
                <option value="inches">Inches</option>
                <option value="centimeters">Centimeters</option>
              </select>
            </div>

            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Neck"
                  value1={values.Neck}
                  name2="Shoulder"
                  value2={values.Shoulder}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Neck, values.Shoulder)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 3: {
        return (
          <div>
            <div>
              <p>Starting Measurements :</p>
            </div>
            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Chest"
                  value1={values.Chest}
                  name2="Bicep"
                  value2={values.Bicep}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Chest, values.Bicep)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }
      case 4: {
        return (
          <div>
            <div>
              <p>Starting Measurements :</p>
            </div>

            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Waist"
                  value1={values.Waist}
                  name2="Hips"
                  value2={values.Hips}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Waist, values.Hips)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 5: {
        return (
          <div>
            <div>
              <p>Ending Measurements :</p>
            </div>

            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Thigh"
                  value1={values.Thigh}
                  name2="Calf"
                  value2={values.Calf}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Thigh, values.Calf)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 6: {
        return (
          <div>
            <div className="darkPrimary  d-flex justify-content-between flex-column modalRounded p-2 shadow">
              <p className="p-2 fw-bold">Offscale Victories</p>

              <div>
                { challeges.length>0 ? (
                challeges.map((challege, index) => (
                  <div className="d-flex justify-content-between" key={index}>
                    <div className="mb-2 mt-3 col-7">
                      <input
                        type="text"
                        className="form-control borderRound shadow"
                        placeholder="Health  Challenge"
                        name={`HealthChallenge${index}`}
                        value={challege.challenge}
                        readOnly
                      />
                    </div>
                    <div className="mb-2 mt-3 col-4">
                      <select
                        onChange={(e)=>updateOffScale(index,e)}
                        className="form-select borderRound"
                        aria-label="Default select example"
                      >
                        {
                          //for de 10 en 10 al 100% con option y guardar el valor en el state de la challege en el offscale victory para luego enviarlo al backend
                          Array.from(Array(11).keys()).map((item, index) => (
                            <option
                              key={index}
                              value={item * 10}
                              name={challege.challenge}
                            >
                              {item * 10}%
                            </option>
                          ))
                          
                        }
                      </select>
                    </div>
                  </div>
                ))) : <p>No challenges</p>}

                {//------------------------------------------------------   
                }
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  setcounter(counter + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 7: {
        return (
          <div className="text-center text-muted">
            <p>Upload your pictures here</p>

            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="dottedEdges col-10 p-2 d-flex justify-content-center align-items-center flex-column">
                <div className="col-2  position-relative d-flex justify-content-center align-items-center">
                  <img className="col-12 opacity-50" src={uploadIcon} alt="" />
                </div>
                <input
                  className="col-9 h-25  bg-dark  position-absolute"
                  type="file"
                  id="imagen-frot"
                  name="imagen-frot"
                  style={{ opacity: 0 }}
                ></input>
              </div>
              <div className="p-1">
                <p>Front view (After)</p>
                <p>
                  Make sure to get your full body shot from head to toes and try
                  to use a background you can use now and again at the end of
                  your 31 Day Journey
                </p>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={async () => await uploadImageView("imagen-frot", "front")}
              >
                Next
              </button>
            </div>
          </div>
        );
      }
      case 8: {
        return (
          <div className="text-center text-muted">
            <p>Upload your pictures here</p>

            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="dottedEdges col-10 p-2 d-flex justify-content-center align-items-center flex-column">
                <div className="col-2  position-relative d-flex justify-content-center align-items-center">
                  <img className="col-12 opacity-50" src={uploadIcon} alt="" />
                </div>
                <input
                  className="col-9 h-25  bg-dark  position-absolute"
                  type="file"
                  id="imagen-side"
                  name="imagen-side"
                  style={{ opacity: 0 }}
                ></input>
              </div>
              <div className="p-1">
                <p>Side view (After)</p>
                <p>
                  Make sure to get your full body shot from head to toes and try
                  to use a background you can use now and again at the end of
                  your 31 Day Journey
                </p>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={async () => await uploadImageView("imagen-side", "side")}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 9:
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn  colorPrimario text-white col-4 borderRound"
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="modal modalContainer d-block d-flex justify-content-center align-items-center px-1"
      >
        <div className="modal-dialog  mb-5  ">
          <div className="modal-content modalBody modalRounded " style={{overflow:'scroll',maxHeight:'90vh'}} >
            <div className="modal-header border-0 d-flex flex-column">
              <h5 className="modal-title border-0 text-center fs-6">
              {name.toUpperCase()} {lastName.toUpperCase()}
              </h5>
              <h5 className="fs-6 mt-3">Final Realising Phase</h5>

              <div className="d-flex  justify-content-center">
                <HexagonText title={day} subTitle="day" />
                <HexagonText title={`${month} ${numberDay}`} subTitle={year} />
              </div>
            </div>
            <div className="modal-body border-0   ">
              <div>{renderSwitch(counter)}</div>
            </div>
            <div className="modal-footer border-0  d-flex justify-content-center" />
          </div>
        </div>
      </form>
    </div>
  );
};
