import axios from 'axios';


const sendChalleges = async (priming)=>{
    try{
        const res = await axios.post('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/addChallengesForUser',priming);
        return await res.data;
    }  catch(error){
        console.log(error);
        return [];
    }
}

const getChallenges = async (data)=>{
    try{
        const res = await axios.post('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/getChallengesForUser',data);
        return await res.data.challenges;
    }  catch(error){
        console.log(error);
        return [];
    }
}

export {sendChalleges,getChallenges};