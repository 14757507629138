import React, { useEffect, useState } from "react";
import { Header } from "../components/Header";
import { PreviousScreen } from "../components/PreviousScreen";
import "../assets/css/components/settings.css";
import bellIcon from "../assets/img/icons/BellIcon.png";
import megaphone from '../assets/img/icons/megaphone.png'
import lockIcon from '../assets/img/icons/lockIcon.png'
import userIcon from '../assets/img/icons/user2Icon.png'
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
export const Settings = () => {

  
  const [notifications, setNotifications] = useState(false);
  const [alerts, setAlert] = useState(false)


useEffect(() => {
  const initialState=Notification.permission==='granted'?true:false
  setNotifications(initialState);
  return () => {
    setNotifications()
  }
}, [])

const alert = useAlert();

  const handleNotifications= async (e)=>{
   try {

   
    const check=e.target.checked;
    if(check===true){


      const permiso= await  Notification.requestPermission();
      const res= await permiso;
      if(res==='granted'){
      if(alerts===false){
        setAlert(true);
        alert.show('Notifications activated', {
          type: 'success',
          timeout:'2500'
        });

        setTimeout(() => {
          setAlert(false)
        }, 2500);
      }
      }else{
       if(alerts===false){
        setAlert(true);
        alert.show('To activate notifications, check your browser settings', {
          type: 'error',
          timeout:'2500'
        });
        setTimeout(() => {
          setAlert(false)
        }, 2500);
       }
      }
     
    }else{
      
      if(alerts===false){
        setAlert(true);
        alert.show('To disable notifications, check your browser settings', {
          type: 'error',
          timeout:'2500'
        });
        setTimeout(() => {
          setAlert(false)
        }, 2500);
      }
      
    }

    
   } catch (error) {
   
     
   }
  }

  return (
    <div className="mb-5">
      <Header lateralMenu />
      <PreviousScreen path="accountSettings" />
      <div  className="col-12 d-flex flex-column justify-content-center align-items-center ">
        <h1 className="text-center">Settings</h1>

        <div className="d-flex flex-column justify-content-center align-items-center container mt-5 mb-1">

        <div  className=" text-decoration-none text-black  d-flex justify-content-center   mt-4 mt-lg-5 mt-lg-5 mb-1 col-12 col-lg-9">
            <div className="d-flex justify-content-center align-items-center ms-2  col-7">
              <img className="col-2 col-lg-1 ms-5" src={bellIcon} alt="" />
              <p className="text-start col-10 col-lg-7 m-1 ">Allow notifications</p>
            <div className="">
            <label className="switch ms-3">
                <input type="checkbox" onChange={handleNotifications} checked={notifications} /> <div></div>
              </label>
            </div>
            </div>
          </div>

          <Link to="changePassword" className=" text-decoration-none  text-black  d-flex justify-content-center   mt-4 mt-lg-5 mb-1 col-12 col-lg-9">
            <div className="d-flex justify-content-center align-items-center   col-7">
              <img className="col-2  col-lg-1  ms-5" src={lockIcon}  alt=""/>
              <p className="text-start col-10 col-lg-7   m-1 me-5">Change password</p>
             
            </div>
          </Link>
          
          <Link to="#" className=" text-decoration-none  text-black  d-flex justify-content-center   mt-4 mt-lg-5 mb-1 col-12 col-lg-9">
            <div className="d-flex justify-content-center align-items-center   col-7">
              <img className="col-2 col-lg-1   ms-5" src={userIcon} alt="" />
              <p className="text-start col-10 col-lg-7  m-1 me-5">Two-factor authentication</p>
             
            </div>
          </Link>

          <Link to="#" className=" text-decoration-none  text-black   d-flex justify-content-center   mt-4 mt-lg-5 mb-1 col-12 col-lg-9">
            <div className="d-flex justify-content-center align-items-center   col-7">
              <img className="col-2 col-lg-1   ms-5" src={megaphone} alt=""/>
              <p className="text-start col-10 col-lg-7  m-1 me-5">Help</p>
             
            </div>
          </Link>


          <Link to="#" className=" text-decoration-none  text-black   d-flex justify-content-center   mt-4 mt-lg-5 mb-1 col-12 col-lg-9">
            <div className="d-flex justify-content-center align-items-center   col-7">
              <img className="col-2  col-lg-1  ms-5" src={userIcon} alt="" />
              <p className="text-start col-10 col-lg-7 m-1 me-5">Account type</p>
             
            </div>
          </Link>


        </div>
      </div>
    </div>
  );
};
