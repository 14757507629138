import React,{useState} from "react";
import "../assets/css/components/myCard.css";
import card from "../assets/img/card.png";
import card2 from "../assets/img/card2.png";

export const MyCard = () => {

  const [numCard, setNumCard] = useState(0);
  
  let idOne=1;
  let idTwo=2;



  return (
    <>
      <div className="d-flex justify-content-center mb-4">
        <div className="myCard">
          <span className={`ellipse ${idOne===numCard?'select-ellipse':''}`} onClick={()=>setNumCard(idOne)}></span>
          <span className="image-card" >
            <img src={card} alt=""/>
          </span>
          <div className="col-5 d-flex flex-column">
            <p className="m-0 letter-min">**** ***022</p>
            <p className="letter-min">Expires 7/25</p>
          </div>
          <p className="aling-edit w-25">Edit</p>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="myCard">
          <span className={`ellipse ${idTwo===numCard?'select-ellipse':''}`} onClick={()=>setNumCard(idTwo)}></span>
          <span className="image-card" >
            <img src={card2} alt=""/>
          </span>
          <div className="col-5 d-flex flex-column">
            <p className="m-0 letter-min">**** ***022</p>
            <p className="letter-min">Expires 7/25</p>
          </div>
          <p className="aling-edit w-25">Edit</p>
        </div>
      </div>
    </>
  );
};
