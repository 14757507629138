import React from 'react';
import exa from '../assets/img/exa.svg';
export const HexagonText = ({ title, subTitle }) => {
	return (
		<div className="d-flex justify-content-center align-items-center  col-4 col-lg-3 mx-2 text-white">
			<div className=" d-flex flex-column align-items-center justify-content-center position-absolute zIndex-5 fs-6  ">
				<p className="p-0 fs-6 m-0 text-center mt-2">{title}</p>
				<p>{subTitle}</p>
			</div>
			<div className="col-12  position-relative ">
				<img className="col-12 " src={exa} alt="" />
			</div>
		</div>
	);
};
