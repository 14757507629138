import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSubMenuArray } from '../actions/menu';

export const OptionMenu = ({text,image,type,model,id,url="subMenu",active}) => {

	/* if(type!=='free'){
		image="s"
	} */
	
	
	 

	const dispatch = useDispatch();
	const handleClick=()=>{
		 dispatch(getSubMenuArray(id,text));

	}
	return (
		<>

			<Link onClick={handleClick} to={url}
				className={`
				text-dark
				text-decoration-none
				d-flex flex-column justify-content-center align-items-center col-5 
				p-5 col-md-2 col-lg-2 opcionMenu m-2
				${model==="2"&&'opMenu2'}
				${model==="3"&&'opMenu3 col-12'}
				${type==='registered'&&'bg-gray'}
				${type==='inactive'&&'bg-gray'}
				`}
				style={{
					backgroundImage: `${type==='active'&&`${`url(${image})`}`}`
				}}
			>
				<div className="">
					<h1 className=" p-0 position-relative zIndex-5 fs-5 fw-bold">{text}</h1>
					{/* <h1 className=" p-0 position-relative zIndex-5">VIDE</h1> */}
				</div>
			</Link>
		</>)
};
