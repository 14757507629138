import React from 'react'
import goldenTrophy from '../assets/img/icons/goldenTrophy.svg'
import bronzeTrophy from '../assets/img/icons/bronzeTrophy.svg'
import silverTrophy from '../assets/img/icons/silverTrophy.svg'

export const ProgressChart = ({ strokeWidth=8, percentage=0 }) => {
    const radius = (50 - strokeWidth / 2);
    const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const getTrophy=()=>{

       if(percentage>0&&percentage<50){
        return bronzeTrophy;
       }else if(percentage>=50&&percentage<75){
        return silverTrophy;
       }else if(percentage>=75){
           return goldenTrophy
       }else{
           return ""
       }

    }

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
			stroke: 'green',
  		strokeLinecap: 'round',
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
    };

    return (
    <div className=" overflow-hidden  position-relative d-flex justify-content-center align-items-center">
          <svg widths={'100%'}
        className={'CircularProgressbar'}
        viewBox="0 0 100 100"
				width={200}
				height={200}
      >
        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
					style={{
						stroke: 'red',
					}}
        />

        <path
          className="CircularProgressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />
 
        <text
          className="CircularProgressbar-text"
          x={50}
          y={50}
					style={{
						fill: '#007dbc',
  					fontSize: '24px',
  					dominantBaseline: 'central',
  					textAnchor: 'middle',
					}}
        >
          {/* {`${percentage}%`} */}
         
        </text>
      </svg>

      <img className=' col-7 position-absolute' src={getTrophy()} alt=""/>
    </div>
      
    );
}
