import React, { useEffect } from 'react'
import { ChartDashboard } from './ChartDashboard'
import { ProgressChart } from './ProgressChart'

import vid from "../assets/videos/home.mp4";
import vid2 from "../assets/videos/video2.mp4";
import axios from 'axios'
import { useState } from 'react'
import { VideoCart } from './VideoCart'
import { getNameUser } from '../helpers/getNameUser';
export const DashBoardFinish = () => {
  const videos = [vid, vid2];
    const [{max,min}, setPercentage] = useState({max:0,min:0,})
    const {name}=getNameUser()
    const api="https://myjson.dit.upm.es/api/bins/bojh";
    const getPorcentaje=async()=>{
     const {data}= await axios.get(api)
     const porc=data
     return porc
    }

    useEffect(() => {
     
      
        getPorcentaje().then(porc=>{
          setPercentage({
              max:porc.percent_challenges_completed,
              min:porc.percent_challenges_incompleted
          })
        })
      
      
      }, [])
    
    return (
        <div className='position-relative p-0  d-flex flex-column container-fluid d-flex w-100'>
           
           <h1 className="text-center">Hello   {name}!</h1>
          <div className='d-flex justify-content-center'>
            <VideoCart videos={videos}/>
         </div>
           <div>
               
           </div>
          <div className="mb-4">
          <h2 className="text-center mt-5 mb-5">Your results</h2>
            <ChartDashboard/>
          </div>
            <div className='text-center mt-5' >
                <h2 >Why it works</h2>
                <h3>Mindset</h3>
                <p className='text-muted mt-5'>31 days reset yout health overview</p>
            </div>

            <div className=" mt-4 flex-column d-flex bg justify-content-center align-items-center">
   
            <ProgressChart percentage={max}/>
             <h3 className="text-muted fs-4 text-center mt-5 mb-4">You're Champion!</h3>
             <p className="text-center p-0 m-0 mt-1">Health Challenges Completed : <span className='text-success fs-2'>{max}%</span></p>          
             <p className="text-center p-0 mb-4 mt-2">Health Challenges Incompleted : <span className='text-danger fs-2'>{min}%</span></p>          
            </div>
           

        </div>
    )
}
