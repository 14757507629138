import React from 'react';
import '../assets/css/components/userCodeModal.css';
export const UserCodeModal = React.memo(({ values, handleInputChange, handleSubmit }) => {
	return (
		<form
			onSubmit={handleSubmit}
			className="modal modalContainer d-block d-flex justify-content-center align-items-center px-3"
		>
			<div className="modal-dialog  mb-5  ">
				<div className="modal-content modalBody modalRounded ">
					<div className="modal-header border-0 ">
						<h5 className="modal-title border-0 text-center">
							Enter your product code to start your journey
						</h5>
					</div>
					<div className="modal-body border-0   ">
						<div className="colorPrimario p-2 rounded-3">
							<input
								value={values}
								onChange={handleInputChange}
								className="form-control  rounded-3"
								type="text"
								name="code"
								autoComplete="off"
								placeholder="Enter yout code"
							/>
						</div>
					</div>
					<div className="modal-footer border-0  d-flex justify-content-center">
						<button type="submit" className="btn  colorPrimario text-white">
							Submit
						</button>
					</div>
				</div>
			</div>
		</form>
	);
});
