import { types } from '../types/types';

const initialState = JSON.parse(localStorage.getItem('submenu')) || {
	Title: "",
	data: [],
}


export const menuReducer = (state = initialState, action) => {

	switch (action.type) {
		case types.subMenu:
			localStorage.setItem('submenu', JSON.stringify(action.payload))

			return action.payload;

		default:
			return state;
	}
};
