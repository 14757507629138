import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	signInWithEmailAndPassword,
	auth,
	provider,
	GoogleAuthProvider,
	providerFacebook,
	FacebookAuthProvider,
	signInWithPopup,
	addDoc,
	collection,
	db
} from '../utils/configFirebase';
import { getUsers } from '../actions/actionsUser';
import '../assets/css/views/login.css';
import { Button } from '../components/Button';
import { Wave } from '../components/Wave';
import { FormLogin } from '../components/FormLogin';

import { useAlert, positions } from 'react-alert';

export const Login = () => {
	const alert = useAlert();
	const history = useHistory();
	const [ error, setError ] = useState(null);

	useEffect(
		() => {
			if (error !== null) {
				alert.show(error, {
					type: 'error'
				});
				setError(null);
			}
		},
		[ error, alert ]
	);

	const redirect = (path) => {
		history.push(path);
	};

	const loginUser = (email, password) => {
		signInWithEmailAndPassword(auth, email, password)
			.then(async (result) => {
				const { user } = result;
				const { uid } = user;
				const users = await getUsers();
				// console.log(user);
				console.log(uid);

				//data user
				const userData = users.find((item) => item.email === user.email);
				///console.log(userData);

				localStorage.setItem('userSession', JSON.stringify(userData));
				alert.show('Login successfully', {
					type: 'success',
					positions: positions.TOP_RIGHT
				});

				setTimeout(() => {
					redirect('/MainMenu');
				}, 2000);


				
			})
			.catch((error) => {
				if (error.code === 'auth/user-not-found') {
					setError('The email is not registered');
				} else if (error.code === 'auth/wrong-password') {
					setError('Password is incorrect');
				} else if (error.code === 'auth/invalid-email') {
					setError('The email is not valid');
				} else {
					setError(error.code);
				}
			});
	};

	const loginWithGoogle = () => {
		signInWithPopup(auth, provider)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				//const credential = GoogleAuthProvider.credentialFromResult(result);
				//const token = credential.accessToken;
				// The signed-in user info.

				//data user
				const user = result.user;
				//console.log(user.providerData[0].providerId);
				//validar si el usuario ya esta registrado
				const users = await getUsers();

				const userExist = users.find((item) => item.email === user.email);
				if (userExist) {
					alert.show('Login successfully', {
						type: 'success',
						positions: positions.TOP_RIGHT
					});

					setTimeout(() => {
						redirect('/MainMenu');
					}, 2000);

					localStorage.setItem('userSession', JSON.stringify(userExist));
				} else {
					let fullName = user.displayName.split(' ');
					let numberNames = fullName.length;
					let names = '';
					let surnames = '';

					if (numberNames > 2) {
						names = fullName[0] + ' ' + fullName[1];
						surnames = fullName[2] + ' ' + fullName[3];
					} else {
						names = fullName[0];
						surnames = fullName[1];
					}
					const newUser = {
						userId: user.uid,
						name: names,
						lastName: surnames,
						email: user.email
					};
					await addDoc(collection(db, 'Users'), newUser);

					alert.show('User registered and logged in successfully', {
						type: 'success',
						positions: positions.TOP_RIGHT
					});

					setTimeout(() => {
						redirect('/MainMenu');
					}, 2000);

					localStorage.setItem('userSession', JSON.stringify(newUser));
				}
			})
			.catch((error) => {
				// Handle Errors here.
				// setError(error.code);
				//setError(error.message);
				// The email of the user's account used.
				// setError(error.email);
				// The AuthCredential type that was used.
				setError(GoogleAuthProvider.credentialFromError(error));

				// ...
			});
	};

	const loginWithFacebook = () => {
		signInWithPopup(auth, providerFacebook)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				//const credential = FacebookAuthProvider.credentialFromResult(result);
				//const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;

				const users = await getUsers();

				const userExist = users.find((item) => item.email === user.email);
				if (userExist) {
					alert.show('Sign in successfully', {
						type: 'success',
						positions: positions.TOP_RIGHT
					});

					setTimeout(() => {
						redirect('/MainMenu');
					}, 2000);

					localStorage.setItem('userSession', JSON.stringify(userExist));
				} else {
					let fullName = user.displayName.split(' ');
					let numberNames = fullName.length;
					let names = '';
					let surnames = '';

					if (numberNames > 2) {
						names = fullName[0] + ' ' + fullName[1];
						surnames = fullName[2] + ' ' + fullName[3];
					} else {
						names = fullName[0];
						surnames = fullName[1];
					}
					const newUser = {
						userId: user.uid,
						name: names,
						lastName: surnames,
						email: user.email
					};
					await addDoc(collection(db, 'Users'), newUser);

					alert.show('User registered and logged in successfully', {
						type: 'success',
						positions: positions.TOP_RIGHT
					});

					setTimeout(() => {
						redirect('/MainMenu');
					}, 2000);
					localStorage.setItem('userSession', JSON.stringify(newUser));
				}
			})
			.catch((error) => {
				// Handle Errors here.
				//  setError(error.code);
				//setError(error.message);
				// The email of the user's account used.
				//   setError(error.email);
				// The AuthCredential type that was used.
				setError(FacebookAuthProvider.credentialFromError(error));

				// ...
			});
	};

	return (
		<div className="height-100 d-flex flex-column justify-content-between">
			<div className="w-100">
				<Wave position="top" text="Login" />
			</div>
			<div className="col-lg-12  d-lg-flex justify-content-lg-center align-items-lg-center">
				<FormLogin normalLogin={loginUser} />
			</div>
			<div>
				<div
					className={`position-absolute zIndex-5  
					 col-lg-12 d-flex  flex-column flex-lg-column justify-content-lg-center align-items-lg-center`}
				>
					<Button
						text={'Register'}
						funcionx={() => {
							redirect('/register');
						}}
					/>
					{/* <Button text={'Facebook'} funcionx={loginWithFacebook} colour="colorAzul" />
					<Button text={'Google'} funcionx={loginWithGoogle} colour="colorRojo" /> */}
				</div>

				<div className=" position-relative mt-3  ">
					<Wave position="bottom" />
				</div>
			</div>
		</div>
	);
};
