import React, { useEffect, useState } from 'react';

import logo from '../assets/img/logo.svg';
//import { AfterPriming } from '../components/AfterPriming';
//import { DashBoardFinish } from '../components/DashBoardFinish';
import { FinalRealisingPhase } from '../components/FinalRealisingPhase';
import { Header } from '../components/Header';
import { PreviousScreen } from '../components/PreviousScreen';
//import { PrimingPhase } from '../components/PrimingPhase';
//import { RealisingPhase } from '../components/RealisingPhase';

export const Test = () => {
	
 const [finalRealisingPhase, setfinalRealisingPhase] = useState(true);
//	let aux=0;
	
  
//	const day=29;




	return (
		<div>
			    <Header lateralMenu />
			<PreviousScreen path="/home" />
			<div className=" d-flex mt-4 px-3 justify-content-around justify-content-md-center">
				<div className="col-8 col-md-3">
					<h1 className="fs-5 text-secondary">Overview</h1>
					<h2 className="fs-1">Dashboard</h2>
				</div>

				<div>
					<img src={logo} alt="logo" />
				</div>
			</div>

		
		{finalRealisingPhase===true&&<FinalRealisingPhase setmodal={setfinalRealisingPhase}/>}
		   
		</div>
	);
};
