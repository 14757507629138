import React, { useState } from "react";
import play from "../assets/img/icons/playIcon.svg";
import '../assets/css/components/playVideoModal.css'
export const PlayModalVideo = ({ video, text = "" }) => {

    const [modal, setmodal] = useState(false)
   
  const handleClick = () => {
   
    setmodal(!modal)
   
  };
  return (
    <>
      <div className="d-flex col-12 col-lg-4 justify-content-center justify-content-xl-end  align-items-center mt-4 mb-4 ">
        <div className="col-12   d-flex justify-content-center align-items-center">
        <img
          onClick={handleClick}
          className="cursor-pointer col-1 mx-3 cursor"
          src={play}
          alt="icon"
        />
        <p className="p-0 m-0">{text}</p>
        </div>
        {modal&&<div onClick={handleClick} className="modal modalContainer   d-block d-flex justify-content-center align-items-center ">
          <div className="modal-dialog d-flex justify-content-center align-items-center mb-5 col-12 ">
              
            <div className="modal-content bg-transparent border-0  play_video_modal_video col-12  ">
                <div className="text-white d-flex justify-content-end mb-3 fs-2">
                    <span onClick={handleClick} className="cursor-pointer text-danger">X</span>
                </div>
              
              <div className="modal-body border-0  d-flex justify-content-center align-items-center  ">
              <iframe  className="play_video_modal_video" src={video} title={text}  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
              <div className="modal-footer border-0  d-flex justify-content-center" />
            </div>
          </div>
        </div>}
      </div>
    </>
  );
};
