import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/img/logo.svg'
import homeIcon from "../assets/img/icons/menu.png";
import { dataMenu } from "../actions/actionMenus";
import '../assets/css/components/lateralMenu.css'
import { useDispatch } from "react-redux";
import { getSubMenuArray } from "../actions/menu";
export const LateralMenu = () => {


	const [data, setData] = useState([]);
  const dispatch = useDispatch();
	useEffect(() => {
	     //cargar lo que viene en dataPriming async
		 async function fetchData() {
			const data = await dataMenu();
			setData(data);
		  }
		
		  fetchData();

      return ()=>{
        setData();
        document.querySelector('body').removeAttribute('style');
      }

	} , [])
	const handleClick=(id,text)=>{
    dispatch(getSubMenuArray(id,text));

 }


  return (
    <>
      <span className="d-flex ">
        <button 
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
         
          aria-controls="offcanvasExample"
          className=" p-0 m-0 bg-transparent border-0 position-relative  col-2 d-flex text-white text-decoration-none mb-"
        >
          <div className="col-9 col-lg-8  ">
            <img className="col-8 " src={homeIcon} alt="menu" />
          </div>
         <span className="ms-1"> iHEALTHe</span>
        </button>
      </span>
   

      <div
        className={`offcanvas offcanvas-start `}
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header p-4 d-flex   flex-column colorPrimario">
          
         <div className="col-12 d-flex justify-content-end">
         <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
           
          ></button>
         </div>
         <div className=" col-12  position-relative">
           <img className="col-3 mb-3" alt="" src={logo} /> 
         </div>
         <div className="container text-dark ">
           <h1>iHEALTe</h1>
           <h2 className="fs-5 fw-light">Challenge Yourself!</h2>
         </div>
        </div>


        <div className="offcanvas-body  mt-3 mb-2 p-0">
          <div className=" col-12 p-0 m-0  d-flex flex-column   ">
           


{
              data?.map((opc, index) => {
               
           
                  return <Link onClick={()=>{handleClick(opc.MenuOptionId,opc.option)}} to={opc.url} key={index} className=" lateral_menu_item col-12  p-2 px-4 mt-1    text-decoration-none text-dark fs-4">
                  {opc.option}
               </Link> ;
              
              })
 
              
            }
          </div>
           
         
        </div>
      </div>
    </>
  );
};
