import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { getKeyPoints } from "../actions/actionKeyPoints";

export const KeyPoints = ({ day, phase }) => {
  const [data, setdata] = useState([]);
// console.log(`la data es :${data}`)


  const getKeyPoint = useCallback(
	async () => {
		const resp=   await getKeyPoints(day,phase);
		return resp;
		 
		},
	  [day,phase]
  )
  



  useEffect(() => {
    const fetchData = async () => {
      const arr= await getKeyPoint();
      setdata(arr);
	 
    };

    fetchData();
   
  }, [getKeyPoint]);

 
  return (
    <div>
      <ul className="fs-6 mt-3 list-unstyled">
        {
			data.map(keyP => {
				return <li key={keyP.KeyPointId} className="my-2">
				<i className="fas fa-hand-point-right" /> {keyP.description}
			  </li>;
			})
		}
      </ul>
    </div>
  );
};
