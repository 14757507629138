import React,{useEffect} from 'react';
import { Header } from '../components/Header';
import { PreviousScreen } from '../components/PreviousScreen';
import logoUser from '../assets/img/icons/logoUser2.svg';
import { useForm } from '../hooks/useForm';

const userLocal = JSON.parse(localStorage.getItem('userSession'));
//userLocal.gender='Male';

const initialState = {
	name: userLocal?.name,
	date: userLocal?.date,
	gender: userLocal?.gender,
	address: userLocal?.address,
	email: userLocal?.email
};

export const MyProfile = () => {

	//traer el usuario de userSession localStorage
	
	


	const { values, handleInputChange, reset } = useForm(initialState);
	

	const validateEmptyFields = (field) => {
		let regexp = /^\s*$/;
		let result = regexp.test(field);

		return !result;
	};

	
	

	// colcolar los valores de user en el formulario
	
	


	const changeGender = (e) => {
		handleInputChange(e);
	};

	const validate = () => {
		const validateGender = validateEmptyFields(values.gender);

		const validateEmail = validateEmptyFields(values.email);
		const validateName = validateEmptyFields(values.name);
		const validateDate = validateEmptyFields(values.date);

		if (validateEmail && validateName && validateDate && validateGender) {
			return true;
		}
		return false;
	};

	// return result;

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate() === true) {
			console.log(values);
		}
	};

	return (
		<div className="mb-5">
		    <Header lateralMenu />
			<PreviousScreen path="/accountSettings" />
			<h1 className="text-center">My profile</h1>
			<div className="d-flex justify-content-center mt-5">
				<div className="col-3 col-lg-1">
					<img className="col-12" src={logoUser} alt="" />
				</div>
			</div>

			<form onSubmit={handleSubmit} className="container col-xl-6 col-xxl-4">
				<div className="mb-3">
					<label htmlFor="exampleInputEmail1" className="form-label">
						Name
					</label>
					<input
						type="text"
						className="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
						name="name"
						value={values.name}
						onChange={handleInputChange}
						required
					/>
					<div id="emailHelp" className="form-text" />
				</div>
				<div className="mb-3">
					<label htmlFor="exampleInputPassword1" className="form-label">
						Date of Birth
					</label>
					<input
						type="date"
						className="form-control"
						id="exampleInputPassword1"
						value={values.date}
						name="date"
						onChange={handleInputChange}
						required
					/>
				</div>

				<div className="mb-3">
					<label htmlFor="exampleInputPassword1" className="form-label">
						Gender
					</label>
					<div className="d-flex container justify-content-around">
						<button
							type="button"
							className={`${values.gender === 'Male'
								? 'colorPrimario text-white'
								: 'btn-white text-dark border '} btn m-1 col-6  p-2 text-center`}
							name="gender"
							value="Male"
							onClick={changeGender}
						>
							Male
						</button>

						<button
							type="button"
							className={`${values.gender === 'Female'
								? 'colorPrimario text-white'
								: 'btn-white text-dark border '} btn m-1 col-6  p-2 text-center`}
							name="gender"
							value="Female"
							onClick={changeGender}
						>
							Female
						</button>
					</div>
				</div>
				<div className="mb-3">
					<label htmlFor="exampleInputPassword1" className="form-label">
						Address
					</label>
					<input
						type="text"
						className="form-control"
						id="exampleInputPassword1"
						value={values.address}
						name="address"
						onChange={handleInputChange}
						required
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="exampleInputPassword1" className="form-label">
						E-mail
					</label>
					<input
						type="Email"
						className="form-control"
						id="exampleInputPassword1"
						value={values.email}
						name="email"
						onChange={handleInputChange}
						required
					/>
				</div>

				<div className="mb-3">
					<label htmlFor="exampleInputPassword1" className="form-label">
						Your ID
					</label>
					<input
						type="text"
						className="form-control bg-white text-muted"
						id="exampleInputPassword1"
						value="#123456"
						disabled
					/>
				</div>

				<div className="d-flex justify-content-center mt-5 ">
					<button type="submit" className=" col-4 btn colorPrimario text-white borderRound">
						Save
					</button>

					<button type="reset" onClick={reset} className=" col-4 btn btn-white borderRound">
						Cancel
					</button>
				</div>
			</form>
		</div>
	);
};
