import React from "react";
import "../assets/css/components/newCard.css";

export const NewCard = () => {
  return (
    <>
      <div className="d-flex flex-column justify-content-start align-items-start options-card mb-4">
       <label className="title-second m-1">Payment method</label>
        <select className="select-card-s" name="" id="">
          <option value="">Select</option>
          <option value="">Visa</option>
          <option value="">Mastercard</option>
          <option value="">American Express</option>
        </select>
      </div>
      <div className="d-flex flex-column justify-content-start align-items-start options-card mb-4">
        <label className="title-second m-1">Card number</label>
        <input
          className="input-card-credit"
          type="text"
          placeholder="4000 0012 7899 "
        />
      </div>
      <div className="row me-4 mb-4">
        <div className="col-7">
         <div className="">
         <label className="title-second m-1">Expire date</label>
         </div>
          <select className="select-expire-date" name="" id="">
            <option value="">Month</option>
            <option value="">1</option>
            <option value="">2</option>
            <option value="">3</option>
          </select>
          <select className="select-expire-date" name="" id="">
            <option value="">Year</option>
            <option value="">2020</option>
            <option value="">2021</option>
            <option value="">2022</option>
          </select>
        </div>
        <div className="col-5">
         <label className="title-second m-1">Security code</label>
          <input className="input-card-credit" type="text" placeholder="123" />
        </div>
      </div>
      <label className="title-primary m-2">Billing information</label>
      <div className="row mb-3 me-4">
        <div className="col-6">
         <label className="title-second m-1">Firts Name</label>
          <input
            className="input-card-credit"
            type="text"
            placeholder="Ricardo"
          />
        </div>
        <div className="col-6 p-0">
         <label className="title-second m-1">Last Name</label>
          <input
            className="input-card-credit"
            type="text"
            placeholder="Gonzalez"
          />
        </div>
      </div>
      <div className="mb-4 me-4">
       <label className="title-second m-1">Adress</label>
        <input
            className="input-card-credit"
            type="text"
            placeholder="Grove Street"
          />
      </div>
      <div className="row mb-3 me-4">
        <div className="col-6">
         <label className="title-second m-1">Country</label>
          <input
            className="input-card-credit"
            type="text"
            placeholder="Canada"
          />
        </div>
        <div className="col-6">
         <label className="title-second m-1">City</label>
          <input
            className="input-card-credit"
            type="text"
            placeholder="Ontana"
          />
        </div>
      </div>
      <div className="row pb-4 me-4">
        <div className="col-6">
         <label className="title-second m-1">Zip Code</label>
          <input className="input-card-credit" type="text" placeholder="M4V" />
        </div>
        <div className="col-6 p-0">
           <label className="title-second m-1">Phone Number</label>
            <input className="input-card-credit" type="text" placeholder="+1213 213 231" />
        </div>
     
      </div>
    </>
  );
};
