import React from 'react';

export const ArrowButton = ({ classCSS = '' }) => {
	return (
		<div className={`py-2 px-2  btnSubmit  rounded-circle p-3 text-white ${classCSS}`}>
			<button type="submit" className="btn shadow-none ">
				<li className="fas fa-arrow-right text-dark" />
			</button>
		</div>
	);
};
