import axios from 'axios';

const dataPriming = async ()=>{
    try{
    const res = await axios.get('https://us-central1-ihealthe-app.cloudfunctions.net/Api/api/Priming/getAllPriming');
        return await res.data.primings;
    }  catch(error){
        console.log(error);
        return [];
    }
}

const sendMeal = async (meal)=>{
    try{
        const res = await axios.post('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/addMealForPriming',meal);
        return await res.data;
    }  catch(error){
        console.log(error);
        return [];
    }
}

const sendPriming = async (priming)=>{
    try{
        const res = await axios.post('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/addPriming',priming);
        return await res.data;
    }  catch(error){
        console.log(error);
        return [];
    }
}

const addMeasurementsAfterPriming = async (measurements)=>{
    try{
        const res = await axios.post('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/addMeasurementsAfterPriming',measurements);
        return await res.data;
    }  catch(error){
        console.log(error);
        return [];
    }
}


export{dataPriming, sendMeal, sendPriming, addMeasurementsAfterPriming};