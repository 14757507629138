import React from 'react';

export const InputMorning = ({ value = '', handleInputChange = () => {} }) => {
	return (
		<div>
			<p>Morning weigh-in after elimination (kg) :</p>
			<div className="">
				<input
					name="morning"
					value={value}
					onChange={handleInputChange}
					type="number"
					className="form-control borderRound shadow"
					placeholder="Morning weigh-in  after elimination "
				/>
			</div>
		</div>
	);
};
