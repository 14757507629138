import { ArrowButton } from '../components/ArrowButton';
import { InputShadow } from '../components/InputShadow';
import '../assets/css/views/register.css';
import '../assets/css/styles.css';
import { Wave } from '../components/Wave';
import { useForm } from '../hooks/useForm';
import React, { useEffect, useState } from 'react';
import { auth, db, createUserWithEmailAndPassword, collection, addDoc } from '../utils/configFirebase';

import { useHistory } from 'react-router-dom';
//import { getUsers } from '../actions/actionsUser';
import { useAlert, positions } from 'react-alert';

const initialState = {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	confirmPassword: ''
};

export const Register = () => {
	const [ error, setError ] = useState(null);

	const history = useHistory();

	const alert = useAlert();

	useEffect(
		() => {
			if (error !== null) {
				alert.show(error, {
					type: 'error'
				});
				setError(null);
			}
		},
		[ error, alert ]
	);

	const register = () => {
		if (
			values.firstName === '' ||
			values.lastName === '' ||
			values.email === '' ||
			values.password === '' ||
			values.passwordConfirm === ''
		) {
			setError('All fields are required');
			return;
		}
		if (values.password !== values.passwordConfirm) {
			setError('Passwords do not match');
			return;
		}

		const newUser = {
			userId: '',
			name: values.firstName,
			lastName: values.lastName,
			email: values.email
		};
		try {
			createUserWithEmailAndPassword(auth, newUser.email, values.password)
				.then(async (res) => {
					newUser.userId = res.user.uid;
					await addDoc(collection(db, 'Users'), newUser);
					localStorage.setItem('userSession',JSON.stringify(newUser))

					alert.show('Success', {
						type: 'success',
						positions: positions.TOP_RIGHT
					});

					setTimeout(() => {
						history.push('/MainMenu');
					}, 2000);
				})
				.catch((error) => {
					if (error.code === 'auth/email-already-in-use') {
						setError('Email is already in use');
					} else if (error.code === 'auth/invalid-email') {
						setError('El email no es valido');
					} else if (error.code === 'auth/weak-password') {
						setError('Email is invalid');
					} else {
						setError('Unknown error');
					}
				});
		} catch (error) {
			setError(error.message);
		}
	};

	// const registerWithGoogle = () => {
	//     signInWithPopup(auth, provider)
	//         .then(async (result) => {
	//             // This gives you a Google Access Token. You can use it to access the Google API.
	//             //const credential = GoogleAuthProvider.credentialFromResult(result);
	//             //const token = credential.accessToken;
	//             // The signed-in user info.
	//             const user = result.user;
	//             //dividir el nombre completo entre nombres y apellidos
	//             let nombreCompleto = user.displayName.split(' ');
	//             let cantidadNombres = nombreCompleto.length;
	//             let nombres = '';
	//             let apellidos = '';

	//             if (cantidadNombres > 2) {
	//                 nombres = nombreCompleto[0] + ' ' + nombreCompleto[1];
	//                 apellidos = nombreCompleto[2] + ' ' + nombreCompleto[3];
	//             } else {
	//                 nombres = nombreCompleto[0];
	//                 apellidos = nombreCompleto[1];
	//             }

	//         /*     console.log(nombres);
	//             console.log(apellidos);
	//             console.log(user.email);
	//             console.log(user.uid); */

	//             //foto: console.log(user.photoURL);
	//             const newUser = {
	//                 userId: user.uid,
	//                 name: nombres,
	//                 lastName: apellidos,
	//                 email: user.email,
	//             }

	//             const users = await getUsers();

	//             console.log(users);
	//             const userExist = users.find(user => user.email === newUser.email);
	//             if (userExist) {
	//                 alert('El usuario ya existe');
	//                 return;
	//             }

	//             await addDoc(collection(db, 'Users'), newUser);
	//             history.push('/');

	//             // ...
	//         }).catch((error) => {
	//             // Handle Errors here.
	//             //setError(error.code);
	//             setError(error.message);
	//             // The email of the user's account used.
	//             //setError(error.email);
	//             // The AuthCredential type that was used.
	//             const credential = GoogleAuthProvider.credentialFromError(error);
	//             setError(credential);

	//             // ...
	//         });
	// }

	// const registerWithFacebook = () => {
	//     signInWithPopup(auth, providerFacebook)
	//         .then(async(result) => {
	//             // The signed-in user info.

	//             const user = result.user;
	//             //dividir el nombre completo entre nombres y apellidos
	//             let nombreCompleto = user.displayName.split(' ');
	//             let cantidadNombres = nombreCompleto.length;
	//             let nombres = '';
	//             let apellidos = '';

	//             if (cantidadNombres > 2) {
	//                 nombres = nombreCompleto[0] + ' ' + nombreCompleto[1];
	//                 apellidos = nombreCompleto[2] + ' ' + nombreCompleto[3];
	//             } else {
	//                 nombres = nombreCompleto[0];
	//                 apellidos = nombreCompleto[1];
	//             }

	//             console.log(nombres);
	//             console.log(apellidos);
	//             console.log(user.email);
	//             console.log(user.uid);
	//             //foto: console.log(user.photoURL);
	//             const newUser = {
	//                 userId: user.uid,
	//                 name: nombres,
	//                 lastName: apellidos,
	//                 email: user.email,
	//             }

	//             const users = await getUsers();

	//             console.log(users);
	//             const userExist = users.find(user => user.email === newUser.email);
	//             if (userExist) {
	//                 alert('El usuario ya existe');
	//                 return;
	//             }

	//             await addDoc(collection(db, 'Users'), newUser);
	//             history.push('/');

	//             // This gives you a Facebook Access Token. You can use it to access the Facebook API.
	//             //const credential = FacebookAuthProvider.credentialFromResult(result);
	//            // const accessToken = credential.accessToken;

	//             // ...
	//         })
	//         .catch((error) => {
	//             // Handle Errors here.
	//             setError(error.code);
	//             setError(error.message);
	//             // The email of the user's account used.
	//             setError(error.email);
	//             // The AuthCredential type that was used.
	//            setError(FacebookAuthProvider.credentialFromError(error));

	//             // ...
	//         });
	// }

	const { values, handleInputChange } = useForm(initialState);

	const handleSubmit = (e) => {
		e.preventDefault();
		register();
	};

	return (
		<form
			onSubmit={handleSubmit}
			className="height-100 d-flex flex-column justify-content-between no-seleccionable"
		>
			<div className="w-100">
				<Wave position="top" text="" />
			</div>
			<div className="containerRegister">
				<div className=" d-flex justify-content-center align-items-center flex-column ">
					<h1 className="mb-5 titulo">Register</h1>
					<div className=" w-80   py-3">
						<div className="mb-3">
							<InputShadow
								icon=" far fa-user "
								placeholder="First Name"
								name="firstName"
								value={values.firstName}
								handleInputChange={handleInputChange}
							/>
						</div>
						<div className="mb-3">
							<InputShadow
								icon=" far fa-user"
								placeholder="Last Name"
								name="lastName"
								value={values.lastName}
								handleInputChange={handleInputChange}
							/>
						</div>
						<div className="mb-3">
							<InputShadow
								icon="far fa-envelope"
								placeholder="Email"
								name="email"
								value={values.email}
								handleInputChange={handleInputChange}
								type="email"
							/>
						</div>
						<div className="mb-3">
							<InputShadow
								icon="fas fa-unlock-alt"
								placeholder="Password"
								name="password"
								value={values.password}
								type="password"
								handleInputChange={handleInputChange}
							/>
						</div>
						<div className="mb-3">
							<InputShadow
								icon="fas fa-unlock-alt"
								placeholder="Confim Password"
								name="passwordConfirm"
								type="password"
								value={values.passwordConfirm}
								handleInputChange={handleInputChange}
							/>
						</div>
						<div>
							{error ? <p className="text-danger">{error}</p> : null //si hay error muestra el mensaje de error
							}
						</div>
					</div>
					<div className=" d-flex w-100  justify-content-center p-3 align-items-center">
						<div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox" required />
								<label className="form-check-label">
									I agree to
									<span className="text-primary"> terms & conditions</span>
								</label>
							</div>
						</div>
						<div className="position-relative    ">
							<ArrowButton />
						</div>
					</div>
				</div>
			</div>

			<div className=" position-relative mt-3  ">
				<Wave position="bottom" />
			</div>
		</form>
	);
};
