import React from 'react';
import { Header } from '../components/Header';
import { PreviousScreen } from '../components/PreviousScreen';
import logo from '../assets/img/logoUser.svg';
import logout from '../assets/img/icons/logout.png';
import iconCard from '../assets/img/icons/credit-card.png';
import iconProfile from '../assets/img/icons/user.png';
import iconSettings from '../assets/img/icons/settings.png';
import { ConfigurationOption } from '../components/ConfigurationOption';
import { useHistory } from 'react-router';
import { getNameUser } from '../helpers/getNameUser';
import '../assets/css/components/accountSettings.css'

export const AccountSettings = () => {
	const history = useHistory();
	const {name,lastName}=getNameUser()
	const logoutSession = () => {
		localStorage.removeItem('userSession');
		history.push('/login');
	};
	return (
		<div className=" min-vh-100 d-flex flex-column justify-content-between">
			<div>
				<Header lateralMenu/>
				<PreviousScreen path="/subMenu" />
				<div className="container bg-dange mb-5 ">
					<div className="d-flex justify-content-center mt-3 align-items-center border-top border-bottom">
						<div className="d-flex mt-4 mb-4">
							<div className="col-4">
								<img className="col-12  round " src={logo} alt="" />
							</div>
							<div className="font-resp d-flex align-items-center">
								<p>
									<span className="fw-light">Welcome!</span> <br />
									<span className="fw-bold">{name} {lastName}</span>
								</p>
							</div>
						</div>

						<div className="d-flex justify-content-end me-2">
							<div className="col-2">
								<img onClick={logoutSession} className="col-12" src={logout} alt="" />
							</div>
						</div>
					</div>

					<div className="container">
						<ConfigurationOption icon={iconCard} text="My cards" path="/card" />
						<ConfigurationOption icon={iconProfile} text="Profile" path="/myProfile" />
						<ConfigurationOption icon={iconSettings} text="Settings" path="/settings" />
					</div>
				</div>
			</div>
			<footer
				id="sticky-footer"
				className="flex-shrink-0 py-4 col-12 colorPrimario text-white footerT d-flex justify-content-center align-items-center"
			>
				<div className=" text-center   ">
					<p className="p-0 m-0"> iHEALTHe 2021</p>
				</div>
			</footer>
		</div>
	);
};
