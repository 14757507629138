import React from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../components/Header';
import '../assets/css/components/subMenuOption.css';
import { SubMenuOption } from '../components/SubMenuOption';
import { PreviousScreen } from '../components/PreviousScreen';
export const SubMenus = () => {
	const lstOpciones = [ ...useSelector((state) => state?.menu?.data) ]||[];

	const titulo = [ ...useSelector((state) => state?.menu?.Title) ];

	
	return (
		<div className="container-fluid p-0 ">
			<Header text="iHEALTHe  Journey" />
			<div className="bg-white border-top-radius position-relative nTop-1 w-100  ">
			<PreviousScreen path="/mainMenu" />

				<h1 className="d-flex mx-4">{titulo}</h1>
				<div className="d-flex p-md-2   justify-content-around justify-content-sm-center  flex-wrap mb-5  mb-5 position-relative">
					{lstOpciones.length !== 0 ? (
						lstOpciones?.map((opc, i) => {
							
							if (opc.active === 'true') {
								return <SubMenuOption key={i} title={opc.option} image={opc.image} path={opc.link} />;
							} else {
								return '';
							}
						})
					) : (
						<div className="loader">Loading...</div>
					)}
				</div>
			</div>
		</div>
	);
};
