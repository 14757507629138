import React, { useState } from 'react';
import '../assets/css/styles.css';
import '../assets/css/views/Home.css';
import { UserCodeModal } from '../components/UserCodeModal';
import { useForm } from '../hooks/useForm';
import homeVideo from '../assets/videos/home.mp4';
import { Header } from '../components/Header';
import { Link } from 'react-router-dom';
import { PreviousScreen } from '../components/PreviousScreen';
import { getNameUser } from '../helpers/getNameUser';

const initialState = {
	code: ''
};
const Home = () => {
	const [ modal, setmodal ] = useState(true);

	const { values, handleInputChange } = useForm(initialState);
	const {name}=getNameUser()

	const handleSubmit = (e) => {
		e.preventDefault();
		setmodal(false);
	};
	return (
		<div className="no-seleccionable">
			<Header />
			<PreviousScreen path="/mainMenu" />

			{modal ? (
				<UserCodeModal values={values.code} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
			) : (
				<div className="container-fluid d-flex flex-column justify-items-center align-items-center mb-5 ">
					<div className="mt-3">
						<h1 className="text-center">
							Welcome {name} to <br /> your new 31 days <br />journey
						</h1>
					</div>
					<div className="d-flex justify-content-center  mt-5  mb-5 col-lg-9 col-xl-6">
						<video className="border" width="100%" height="" src={homeVideo} controls />
					</div>

					<div>
						<Link className="btn colorPrimario text-white shadow rounded borderRound" to="/dashboard">
							<span className="mx-2">Begin</span>
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

export default Home;
