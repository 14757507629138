import React, { useState } from "react";
import { Header } from "../components/Header";
import "../assets/css/components/subMenuOption.css";
import "../assets/css/components/cards.css";
import { PreviousScreen } from "../components/PreviousScreen";
import { MyCard } from "../components/MyCard";
import { NewCard } from "../components/NewCard";

export const Card = () => {
  const [card, setCard] = useState("myCard");

  const changeCard = () => {
    if (card === "myCard") {
      setCard("newCard");
    } else {
      setCard("myCard");
    }
  };

  return (
    <div className="container-fluid p-0 ">
      <Header lateralMenu={true} text="" />
      <div className="bg-white border-top-radius position-relative nTop-1 w-100  ">
        <PreviousScreen path="/accountSettings" />
        <div className="select-card d-flex justify-content-center ">
          <div className="rectangle-48">
            <div
              className={`
            ${
              card === "myCard"
                ? "rectangle-20 bg-select color-white"
                : "bg-no-select"
            }
            
            d-flex 
            justify-content-center 
            align-items-center`}
              onClick={changeCard}
            >
              <div className="my-card">My Card</div>
            </div>
            {/* New Card */}
            <div
              className={`
              ${
                card === "newCard"
                  ? "rectangle-20 bg-select color-white"
                  : "bg-no-select"
              }
              
            d-flex justify-content-center align-items-center`}
              onClick={changeCard}
            >
              <div className="new-card">New Card</div>
            </div>
          </div>
        </div>
        <div className="mt-4">
        {card === "myCard" ? <MyCard /> : <NewCard />}
        </div>
      </div>
    </div>
  );
};
