import React from 'react';
import { Link } from 'react-router-dom';

export const SubMenuOption = ({ title, image, path = '#' }) => {

	if(path.length<3){
		path="#"
	}
	return (
		<Link
			to={path}
			className=" card col-5 col-xss-12  col-sm-5 col-md-4 col-lg-3 col-xl-2 opcionSubMenu  opcionSubMenuHeight text-decoration-none mt-sm-5 m-sm-4 mb-5"
		>
			<div className="card-header border-3 border-0  text-dark">
				<p className="card-title fs-6   ">{title}</p>
			</div>
			{
				image && (
					<img src={`${image}`} className="card-img-bottom opcionSubMenu" alt="..." />
				) 
			}
			
		</Link>
	);
};
