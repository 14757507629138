import React from 'react';
import { Link } from 'react-router-dom';
import nextIcon from '../assets/img/icons/next.png';
export const ConfigurationOption = ({ icon, text, path = '/mainMenu' }) => {
	return (
		<div className=" mt-5 d-flex justify-content-between align-items-center">
			<Link to={path} className="d-flex text-decoration-none">
				<div className="col-1 me-4">
					<img className="col-12" src={icon} alt="" />
				</div>
				<div className="text-dark ">{text}</div>
			</Link>
			<div className=" d-flex justify-content-end">
				<Link to={path} className="col-1">
					<img className="col-12" src={nextIcon} alt="" />
				</Link>
			</div>
		</div>
	);
};
