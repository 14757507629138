import axios from 'axios';
import {  db,  collection, getDocs } from '../utils/configFirebase';

export const dataMenu = async ()=>{
    try{
    const res = await axios.get('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/Priming/getMenuOptions');
        return await res.data.MenuOptions;
    }  catch(error){
        console.log(error);
        return [];
    }
    

}


export const dataSubMenu = async ()=>{
    try{
    const res = await axios.get('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/getAllSubMenuOptions');
        return await res.data.subMenu;
    }  catch(error){
        console.log(error);
        return [];
    }
}

export const getSubMenuForFirebase = async () => {
    const usuarioCollection = collection(db, 'SubMenu');

    const dataRes = await getDocs(usuarioCollection);
    const lista =  dataRes.docs.map(doc => ({ idSubMenu: doc.id, ...doc.data() }));
    console.log(lista);
    return lista;
}