import React from 'react';
import im1 from '../assets/img/1.svg';
import im2 from '../assets/img/2.svg';
import logo from '../assets/img/logo.svg';
import '../assets/css/components/Wave.css';
import '../assets/css/styles.css';
import { useWindowsSize } from '../hooks/useWindowsSize';
export const Wave = ({ position = 'top', text = '' }) => {
	const { width } = useWindowsSize();
	return (
		<div
			className={`waveContainer d-flex no-seleccionable   w-100 m-0 p-0 flex-column    align-items-center ${text ===
				'' && 'footer'}`}
		>
			<div className={` col-lg-12 ${position !== 'top' && width > 1000 && 'd-flex  justify-content-end'}`}>
				<img
					className={`col-12 col-lg-8  col-xl-3  left-x  postion-relative display-lg-absolute'} 
                         ${position === 'top' ? ' left-x ' : ' left-y '}
                         ${position !== 'top' && 'waveContainer'}
                      `}
					src={`${position !== 'top' ? im1 : im2}`}
					alt=""
				/>
			</div>

			{position === 'top' && (
				<div
					className={`position-absolute d-flex   justify-content-end  mt-5 px-3  col-12 col-md-12 col-xl-4  `}
				>
					<img className="col-3 col-md-2 col-xl-2 position-absolute " alt="" src={logo} />
				</div>
			)}

			{text !== '' && (
				<div className="mt-6  position-relative  d-flex w-100   ">
					<p className="titulo  w-100 text-center">{text}</p>
				</div>
			)}
		</div>
	);
};
