import axios from 'axios';

export const dataFaqs = async ()=>{
    try{
    const res = await axios.get('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/priming/getFAQS');
        return await res.data.FAQS;
    }  catch(error){
        console.log(error);
        return [];
    }

}