import React from 'react';

export const InputMealSnack = ({
	nameMeal = 'meal',
	nameTime = 'time',
	nameCals = 'cals',
	valueMeal,
	handleInputChange = () => {},
	valueTime,
	valueCals,
	number,
}) => {
	return (
		<div>
			<p>{number}. Meal/Snack : ( F600 cals-m 800 cals)</p>
			<div className="">
				<input
					value={valueMeal}
					onChange={handleInputChange}
					type="text"
					className="form-control borderRound shadow "
					placeholder="Meal/Snack"
					name={nameMeal}
				/>
			</div>
			<div className="d-flex justify-content-between  ">
				<input
					value={valueTime}
					onChange={handleInputChange}
					type="time"
					className="form-control borderRound m-1 shadow "
					placeholder="Time"
					name={nameTime}
				/>
				<input
					value={valueCals}
					onChange={handleInputChange}
					type="number"
					className="form-control borderRound m-1 shadow"
					placeholder="Cals"
					name={nameCals}
				/>
			</div>
		</div>
	);
};
