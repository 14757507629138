import React from "react";

export const RoundInput = ({ text,type, placeholder, name, value, handleInputChange }) => {
  return (
    <div className="col-12">
      {text&&<p>{text}</p>}
      <div className="">
        <input
          name={name}
          value={value}
          onChange={handleInputChange}
          type={type}
          className="form-control borderRound shadow"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
