import React, { useState } from "react";
import uploadIcon from "../assets/img/icons/upload.png";
import { useForm } from "../hooks/useForm";
import { isEmpty } from "validator";
import { HexagonText } from "./HexagonText";
import { MeasurementsInputs } from "./MeasurementsInputs";
import { sendChalleges } from "../actions/actionChallenges";
import { addMeasurementsAfterPriming } from "../actions/actionPriming";
import moment from "moment";
import { uploadFile } from "../utils/uploadWithFirebase";
import { sendPicture } from "../actions/actionPictures";
import { getNameUser } from "../helpers/getNameUser";



const initialState = {
  Thigh: "",
  Calf: "",
  Chest: "",
  Bicep: "",
  Neck: "",
  Shoulder: "",
  CurrentWeight: "",
  Waist: "",
  Hips: "",
  healthChallenge1: "",
  healthChallenge2: "",
  healthChallenge3: "",
  healthChallenge4: "",
  healthChallenge5: "",
};

export const AfterPriming = ({ setmodal, date ,setmodal2}) => {
  const year = moment(date).format("YYYY");
  const numberDay = moment(date).format("Do").replace("th", "");
  const month = moment(date).format("MMMM").substring(0, 3);
  const [counter, setcounter] = useState(1);
  const { values, handleInputChange } = useForm(initialState);
  const [unit, setUnit] = useState("inches");
  const [bloodPressure, setBloodPressure] = useState(false);
  const [diabetes, setDiabetes] = useState(false);
  const [sleep, setSleep] = useState(false);
  const [arthritis, setArthritis] = useState(false);
  const {name,lastName}=getNameUser()

  const uploadImageView = async (NameIma,modo) => {
    try{
  
      const image = document.getElementById(NameIma).files[0];
      const imageName = image.name;
      const converterBlobImage = new File([image], imageName, {
        type: image.type,
      });
  
      const imageUrl = await uploadFile(converterBlobImage, imageName);
  
      await sendPictureView(imageUrl,modo);
  
      setcounter(counter + 1);
    }catch(e){
     
  
    }
    };
  
    const sendPictureView = async (url,modo) => {
      const dataPircture = {
        photo: url,
        mode: modo,
        phase: "After priming Phase",
        userId: getUserLocalStorage(),
        date: new Date().toISOString().slice(0, 10),
      };
      let res = await sendPicture(dataPircture);
      console.log(res);
    };



  const listChallenges = () => {
    const user_id = getUserLocalStorage();
    const list = {
      challenges: [
        {
          user_id,
          challenge: values.healthChallenge1,
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: values.healthChallenge2,
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: values.healthChallenge3,
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: values.healthChallenge4,
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: values.healthChallenge5,
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: bloodPressure ? "Blood Pressure" : "",
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: arthritis ? "Arthritis" : "",
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: diabetes ? "Diabetes" : "",
          off_scale_victory: 0,
        },
        {
          user_id,
          challenge: sleep ? "Sleep" : "",
          off_scale_victory: 0,
        },
      ],
    };

    const listValid = {
      challenges: [],
    };

    for (let i = 0; i < list.challenges.length; i++) {
      if (list.challenges[i].challenge !== "") {
        listValid.challenges.push(list.challenges[i]);
      }
    }

    return listValid;
  };

  async function sendChallengesView(challenges) {
    const resChallenges = await sendChalleges(challenges);
    console.log(challenges);
    console.log(resChallenges);
  }

  const day = 2;
  const handleSubmit = (e) => {
    e.preventDefault();
    setmodal(false);
    setTimeout(() => {
      setmodal2(true);
    }, 2400);
    sendPrimingView();
    sendChallengesView(listChallenges());
    console.log(values);
  };
  const validateFieldsList = (str1, str2) => {
    let regexp = /^\s*$/;
    let result1 = regexp.test(str1);
    let result2 = regexp.test(str2);
    let resp = false;
    if (result1 || result2) {
      resp = true;
    }

    return !resp;
  };

  const handleChangeUnit = (e) => {
    setUnit(e.target.value);
  };

  async function sendPrimingView() {
    const measurements = {
      current_weight: values.CurrentWeight,
      unit: unit,
      neck_size: values.Neck,
      chest_size: values.Chest,
      waist_size: values.Waist,
      thigh_size: values.Thigh,
      shoulder_size: values.Shoulder,
      bicep_size: values.Bicep,
      hips_size: values.Hips,
      calf_size: values.Calf,
      user_id: getUserLocalStorage(),
      phase: "after_priming",
    };

    const resMeasurements = await addMeasurementsAfterPriming(measurements);
    console.log(resMeasurements);
    console.log(measurements);

    // setCheck(false);
  }

  function getUserLocalStorage() {
    let user = JSON.parse(localStorage.getItem("userSession"));
    return user.userId;
  }

  const renderSwitch = (counter) => {
    switch (counter) {
      case 1: {
        return (
          <div>
            <p>Current Weight (kg) :</p>
            <div className="">
              <input
                name="CurrentWeight"
                value={values.CurrentWeight}
                onChange={handleInputChange}
                type="number"
                className="form-control borderRound shadow"
                placeholder="Current Weight"
              />
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (!isEmpty(values.CurrentWeight)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 2: {
        return (
          <div>
            <div>
              <p>Starting Measurements :</p>
            </div>
            <div>
              <div>
                <p className="fs-6">Choose the unit of measure to use :</p>
              </div>
              <select
                onChange={handleChangeUnit}
                className="form-select borderRound"
                aria-label="Default select example"
              >
                <option value="inches">Inches</option>
                <option value="centimeters">Centimeters</option>
              </select>
            </div>

            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Neck"
                  value1={values.Neck}
                  name2="Shoulder"
                  value2={values.Shoulder}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Neck, values.Shoulder)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 3: {
        return (
          <div>
            <div>
              <p>Starting Measurements :</p>
            </div>
            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Chest"
                  value1={values.Chest}
                  name2="Bicep"
                  value2={values.Bicep}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Chest, values.Bicep)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }
      case 4: {
        return (
          <div>
            <div>
              <p>Starting Measurements :</p>
            </div>

            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Waist"
                  value1={values.Waist}
                  name2="Hips"
                  value2={values.Hips}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Waist, values.Hips)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 5: {
        return (
          <div>
            <div>
              <p>Starting Measurements :</p>
            </div>

            <div>
              <div className="mt-2">
                <MeasurementsInputs
                  name1="Thigh"
                  value1={values.Thigh}
                  name2="Calf"
                  value2={values.Calf}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (validateFieldsList(values.Thigh, values.Calf)) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 6: {
        return (
          <div>
            <div className="darkPrimary  d-flex justify-content-between flex-column modalRounded p-2 shadow">
              <p className="p-2 fw-bold">Health Challenges</p>
              <div className="d-flex justify-content-center">
                <div className="form-check col-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={bloodPressure}
                    onChange={() => setBloodPressure(!bloodPressure)}
                    id="bloodPresure"
                  />
                  <label className="form-check-label" htmlFor="bloodPresure">
                    blood pressure
                  </label>
                </div>

                <div className="form-check col-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={arthritis}
                    onChange={() => setArthritis(!arthritis)}
                    id="Arthritis"
                  />
                  <label className="form-check-label" htmlFor="Arthritis">
                    Arthritis
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-center mt-2">
                <div className="form-check col-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={sleep}
                    onChange={() => setSleep(!sleep)}
                    id="Sleep"
                  />
                  <label className="form-check-label" htmlFor="Sleep">
                    Sleep apnea
                  </label>
                </div>
                <div className="form-check col-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={diabetes}
                    onChange={() => setDiabetes(!diabetes)}
                    id="Diabetes"
                  />
                  <label className="form-check-label" htmlFor="Diabetes">
                    Diabetes
                  </label>
                </div>
              </div>

              <div>
                <div className="d-flex justify-content-center">
                  <div className="mb-2 mt-3 col-11">
                    <input
                      type="text"
                      className="form-control borderRound shadow"
                      placeholder="Health  Challenge"
                      name="healthChallenge1"
                      value={values.healthChallenge1}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="mb-2 mt-1 col-11">
                    <input
                      type="text"
                      className="form-control borderRound shadow"
                      name="healthChallenge2"
                      placeholder="Health  Challenge"
                      value={values.healthChallenge2}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="mb-2 mt-1 col-11">
                    <input
                      type="text"
                      className="form-control borderRound shadow"
                      placeholder="Health  Challenge"
                      name="healthChallenge3"
                      value={values.healthChallenge3}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="mb-2 mt-1 col-11">
                    <input
                      type="text"
                      className="form-control borderRound shadow"
                      placeholder="Health  Challenge"
                      name="healthChallenge4"
                      value={values.healthChallenge4}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="mb-2 mt-1 col-11">
                    <input
                      type="text"
                      className="form-control borderRound shadow"
                      placeholder="Health  Challenge"
                      name="healthChallenge5"
                      value={values.healthChallenge5}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate1 = validateFieldsList(
                    values.healthChallenge1,
                    values.healthChallenge2
                  );
                  const validate2 = validateFieldsList(
                    values.healthChallenge3,
                    values.healthChallenge4
                  );
                  const validate3 = !isEmpty(values.healthChallenge5);

                  if (validate1 && validate2 && validate3) {
                    setcounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 7: {
        return (
          <div className="text-center text-muted">
            <p>Upload your pictures here</p>

            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="dottedEdges col-10 p-2 d-flex justify-content-center align-items-center flex-column">
                <div className="col-2  position-relative d-flex justify-content-center align-items-center">
                  <img className="col-12 opacity-50" src={uploadIcon} alt="" />
                </div>
                <input
                  className="col-9 h-25  bg-dark  position-absolute"
                  type="file"
                  id="imagen-frot"
                  name="imagen-frot"
                  style={{ opacity: 0 }}
                ></input>
              </div>
              <div className="p-1">
                <p>Front view (Before)</p>
                <p>
                  Make sure to get your full body shot from head to toes and try
                  to use a background you can use now and again at the end of
                  your 31 Day Journey
                </p>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={async () => await uploadImageView("imagen-frot","front")}
              >
                Next
              </button>
            </div>
          </div>
        );
      }
      case 8: {
        return (
          <div className="text-center text-muted">
            <p>Upload your pictures here</p>

            <div className="d-flex justify-content-center flex-column align-items-center">
              <div className="dottedEdges col-10 p-2 d-flex justify-content-center align-items-center flex-column">
                <div className="col-2  position-relative d-flex justify-content-center align-items-center">
                  <img className="col-12 opacity-50" src={uploadIcon} alt="" />
                </div>
                <input
                  className="col-9 h-25  bg-dark  position-absolute"
                  type="file"
                  id="imagen-side"
                  name="imagen-side"
                  style={{ opacity: 0 }}
                ></input>
              </div>
              <div className="p-1">
                <p>Side view (Before)</p>
                <p>
                  Make sure to get your full body shot from head to toes and try
                  to use a background you can use now and again at the end of
                  your 31 Day Journey
                </p>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-between">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setcounter(counter - 1)}
              >
                Back
              </button>

              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={async () => await uploadImageView("imagen-side","side")}
              >
                Next
              </button>
            </div>
          </div>
        );
      }

      case 9:
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn  colorPrimario text-white col-4 borderRound"
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="modal modalContainer d-block d-flex justify-content-center align-items-center px-1"
      >
        <div className="modal-dialog  mb-5  ">
          <div className="modal-content modalBody modalRounded ">
            <div className="modal-header border-0 d-flex flex-column">
              <h5 className="modal-title border-0 text-center fs-6">
              {name.toUpperCase()} {lastName.toUpperCase()}
              </h5>
              <h5 className="fs-6 mt-3">Priming phase</h5>

              <div className="d-flex  justify-content-center">
                <HexagonText title={day} subTitle="day" />
                <HexagonText title={`${month} ${numberDay}`} subTitle={year} />
              </div>
            </div>
            <div className="modal-body border-0   ">
              <div>{renderSwitch(counter)}</div>
            </div>
            <div className="modal-footer border-0  d-flex justify-content-center" />
          </div>
        </div>
      </form>
    </div>
  );
};
