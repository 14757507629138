import React from 'react';
import { Link } from 'react-router-dom';
import { LateralMenu } from './LateralMenu';
import homeIcon from '../assets/img/icons/menu.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAuth, signOut } from "firebase/auth";
export const Header = React.memo(({ text,lateralMenu }) => {
	const handleLogout = () => {
		localStorage.removeItem('userSession');
	

const auth = getAuth();
signOut(auth).then(() => {
console.log('bye')
}).catch((error) => {
 console.log(error)
});
	};
	const history = useHistory();
	const hanldeRedirect=()=>{
		history.push('/mainMenu')

	}
	return (
		<div className="colorPrimario text-white d-flex p-3 p-lg-2 justify-content-end  flex-column">
			<div className=" mt-4 fs-2 ms-2 d-flex justify-content-between m-3">
				{
					lateralMenu?<LateralMenu/>:  <span className="d-flex ">
					<button 
					 
					  className=" p-0 m-0 bg-transparent border-0 position-relative  col-2 d-flex text-white text-decoration-none mb-"
					  onClick={hanldeRedirect}
					>
					  <div className="col-9 col-lg-8  ">
						<img className="col-8 " src={homeIcon} alt="menu" />
					  </div>
					 <span className="ms-1"> iHEALTHe</span>
					</button>
				  </span>
				}
				
				<Link to="/login" onClick={handleLogout}>
					<i className="fas fa-sign-out-alt text-dark" />
				</Link>
			</div>
			{text && (
				<div className=" text-center d-flex  justify-content-center  align-items-center mt-4">
					<p className="fs-3 text-center text-dark fw-bolder">{text}</p>
				</div>
			)}
			
		</div>
	);
});
