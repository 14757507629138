import React, { useEffect, useState } from 'react';

import logo from '../assets/img/logo.svg';
import { AfterPriming } from '../components/AfterPriming';
import { DashBoardFinish } from '../components/DashBoardFinish';
import { FinalRealisingPhase } from '../components/FinalRealisingPhase';
import { Header } from '../components/Header';
import { PreviousScreen } from '../components/PreviousScreen';
import { PrimingPhase } from '../components/PrimingPhase';
import { RealisingPhase } from '../components/RealisingPhase';

export const DashBoard = () => {
	const [ modal, setmodal ] = useState(true);
	const [afterPriming, setAfterPriming] = useState(false);
	const [dashFinish, setdashFinish] = useState(true)
	const [realisingPhase, setRealisingPhase] = useState(false)
 const [finalRealisingPhase, setfinalRealisingPhase] = useState(false);
	let aux=0;
	
  
	const day=29;






	useEffect(() => {
		
		if(modal===false){
			setTimeout(() => {
				if(aux===0){
					console.log('exitoo')
					setAfterPriming(true)
					aux++;
				}
				
				
				
			}, 2400);
		}
		setdashFinish(true)
	}, [modal,aux])
	return (
		<div>
			    <Header lateralMenu />
			<PreviousScreen path="/home" />
			<div className=" d-flex mt-4 px-3 justify-content-around justify-content-md-center">
				<div className="col-8 col-md-3">
					<h1 className="fs-5 text-secondary">Overview</h1>
					<h2 className="fs-1">Dashboard</h2>
				</div>

				<div>
					<img src={logo} alt="logo" />
				</div>
			</div>

			{modal===true && <PrimingPhase day={1} setmodal={setmodal} date={new Date()} />}
			{afterPriming===true && <AfterPriming setmodal={setAfterPriming} setmodal2={setRealisingPhase}  date={new Date()} />}
		{dashFinish===true &&<DashBoardFinish />}

		{realisingPhase===true&&<RealisingPhase setRealisingPhase={setRealisingPhase} setmodal2={setfinalRealisingPhase} day={day} date={new Date()}/>}
		{finalRealisingPhase===true&&<FinalRealisingPhase setmodal={setfinalRealisingPhase}/>}
		   
		</div>
	);
};
