import React from "react";
import { useState } from "react";

import nextIcon from "../assets/img/icons/next.png";
import backIcon from "../assets/img/icons/back.png";
export const VideoCart = ({videos}) => {

  const dataLen = videos.length;
  const [select, setselect] = useState(0);



  const handleNext = () => {
    if (select + 1 < dataLen) {
      setselect(select + 1);
    } else {
      setselect(0);
    }
  };

  const handleBack = () => {
    if ((select -1) >= 0) {
      setselect(select - 1);
    } else {
      setselect(dataLen-1);
    }
  };


  return (
    <div className="position-relative d-flex justify-content-center align-items-center">
      <div className="col-12  d-flex justify-content-center align-items-center">
          {videos.map((vi,index)=>(
               <video key={index} playsInline type="video/mp4" className={`${index===select?'col-9 col-xl-7 borderRound':'d-none '}`} src={videos[index]}  controls />
          ))}
      </div>
      <div className="d-flex col-12 col-xl-10  position-absolute  justify-content-between align-content-center">
        
        <div className="d-flex justify-content-start align-items-center p-0">
          <button  onClick={handleBack} className=" col-3 col-lg-2 bg-transparent border-0">
            <img className="col-12" src={backIcon} alt="arrow" />
          </button>
        </div>

        <div className="d-flex justify-content-end align-items-center p-0">
          <button onClick={handleNext} className=" col-3 col-lg-2 bg-transparent border-0">
            <img className="col-12" src={nextIcon} alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};
