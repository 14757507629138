import React, {  useState } from "react";
import { HexagonText } from "../components/HexagonText";
import { InputMorning } from "../components/InputMorning";
import { useForm } from "../hooks/useForm";
import { InputMealSnack } from "../components/InputMealSnack";
import { InputTotalCalories } from "../components/InputTotalCalories";
import { sendMeal, sendPriming } from "../actions/actionPriming";

import { KeyPoints } from "../components/KeyPoints";

import "../assets/css/components/primingPhase.css";
import moment from "moment";
import { getNameUser } from "../helpers/getNameUser";

const initialState = {
  morning: "",
  meal1: "",
  time1: "",
  cals1: "",
  meal2: "",
  time2: "",
  cals2: "",
  meal3: "",
  time3: "",
  cals3: "",
  meal4: "",
  time4: "",
  cals4: "",
  meal5: "",
  time5: "",
  cals5: "",
  meal6: "",
  time6: "",
  cals6: "",
  Thigh: "",
  Calf: "",
  Chest: "",
  Bicep: "",
  Neck: "",
  Shoulder: "",
  CurrentWeight: "",
  Waist: "",
  Hips: "",
  offScaleVictory1: "",
  offScaleVictory2: "",
  offScaleVictory3: "",
  offScaleVictory4: "",
  offScaleVictory5: "",
};

export const PrimingPhase = ({ setmodal, day, date }) => {
  const year = moment(date).format("YYYY");
  const numberDay = moment(date).format("Do").replace("th", "");
  const month = moment(date).format("MMMM").substring(0, 3);
  const [counter, setCounter] = useState(1);
  const { values, handleInputChange } = useForm(initialState);
  const {name,lastName}=getNameUser()


  async function sendPrimingView() {
    const priming = {
      userId: getUserLocalStorage(),
      totalCalories: sumCals(values),
      date: new Date().toLocaleDateString(),
      upon_rising: check,
      time: new Date().toLocaleTimeString().substring(0, 5),
      morning_weight: values.morning,
      day: day,
      "5_PM": check5PM,
    };
    const resPriming = await sendPriming(priming);
    console.log(priming);
    console.log(resPriming);
  }


 

  function sumCals(obj) {
    let sum = 0;
    sum = sum + parseFloat(obj.cals1);
    sum = sum + parseFloat(obj.cals2);
    sum = sum + parseFloat(obj.cals3);
    sum = sum + parseFloat(obj.cals4);
    sum = sum + parseFloat(obj.cals5);
    sum = sum + parseFloat(obj.cals6);
    sum = sum.toFixed(2);
    return sum;
  }

  const validateEmptyFields = (field) => {
    let regexp = /^\s*$/;
    let result = regexp.test(field);
    return !result;
  };

  const validateNumber = (number) => {
    let regexp = /^[0-9]+([,.][0-9]+)?$/g;
    let result = regexp.test(number);
    return result;
  };

  const validateMeals = (nameMeal, time, cals) => {
    let result = false;

    if (
      validateNumber(cals) &&
      validateEmptyFields(time) &&
      validateEmptyFields(nameMeal)
    ) {
      result = true;
    }

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(sumCals(values));

    setmodal(false);
    console.log("set modal false");

    sendPrimingView();
  };

  function getUserLocalStorage() {
    let user = JSON.parse(localStorage.getItem("userSession"));
    return user.userId;
  }
  const [check, setCheck] = useState(false);
  const [check5PM, setCheck5PM] = useState(false);

  async function sendMeals(meals, time, cals) {
    const meal = {
      userId: getUserLocalStorage(),
      number: counter - 1,
      name: meals,
      time: time,
      calories: cals,
      type: "priming",
      date: new Date().toLocaleDateString(),
    };
    const res = await sendMeal(meal);

    console.log(res);
    console.log(meal);
    setCounter(counter + 1);
  }

  const renderSwitch = (param) => {
    //imprimir al seleccionar el checkbox

    switch (param) {
      case 1:
        return (
          <div>
            <div>
              <h2 className="fs-6 text-center">
                iHEALTHe Micro-Nutrient Release
              </h2>
              <h3 className="fs-6 text-center">Homeopathic Formula:</h3>
              <div className="d-flex justify-content-evenly ">
                <div className=" col-4 p-2 d-flex align-center-start justify-content-between">
                  <div className=" col-5 ">
                    <input
                      className="form-check-input    w-100 w-md-75 h-50 "
                      type="checkbox"
                      id="check1"
                      onChange={() => setCheck(!check)}
                    />
                  </div>
                  <div className=" col-5 ">
                    <label
                      className="form-check-labe text-nowrap"
                      htmlFor="check1"
                    >
                      UPON <br /> RISING <br /> (3 SPRAYS)
                    </label>
                  </div>
                </div>

                <div className=" col-4 p-2 d-flex align-center-start justify-content-between">
                  <div className=" col-5 ">
                    <input
                      className="form-check-input   w-100 h-50  w-md-75  "
                      type="checkbox"
                      id="check2"
                      onChange={() => setCheck5PM(!check5PM)}
                    />
                  </div>
                  <div className=" col-5 ">
                    <label
                      className="form-check-labe text-nowrap "
                      htmlFor="check2"
                    >
                      5PM <br /> (3 SPRAYS)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <InputMorning
              value={values.morning}
              handleInputChange={handleInputChange}
            />

            <div className=" d-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateNumber(values.morning);
                  validate && setCounter(counter + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );

      case 2:
        return (
          <div>
            <InputMealSnack
              number={1}
              nameMeal="meal1"
              nameTime="time1"
              nameCals="cals1"
              valueMeal={values.meal1}
              valueCals={values.cals1}
              valueTime={values.time1}
              handleInputChange={handleInputChange}
            />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateMeals(
                    values.meal1,
                    values.time1,
                    values.cals1
                  );
                  validate &&
                    sendMeals(values.meal1, values.time1, values.cals1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <InputMealSnack
              number={2}
              nameMeal="meal2"
              nameTime="time2"
              nameCals="cals2"
              valueMeal={values.meal2}
              valueCals={values.cals2}
              valueTime={values.time2}
              handleInputChange={handleInputChange}
            />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateMeals(
                    values.meal2,
                    values.time2,
                    values.cals2
                  );
                  validate &&
                    sendMeals(values.meal2, values.time2, values.cals2);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <InputMealSnack
              number={3}
              nameMeal="meal3"
              nameTime="time3"
              nameCals="cals3"
              valueMeal={values.meal3}
              valueCals={values.cals3}
              valueTime={values.time3}
              handleInputChange={handleInputChange}
            />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateMeals(
                    values.meal3,
                    values.time3,
                    values.cals3
                  );
                  validate &&
                    sendMeals(values.meal3, values.time3, values.cals3);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <InputMealSnack
              number={4}
              nameMeal="meal4"
              nameTime="time4"
              nameCals="cals4"
              valueMeal={values.meal4}
              valueCals={values.cals4}
              valueTime={values.time4}
              handleInputChange={handleInputChange}
            />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateMeals(
                    values.meal4,
                    values.time4,
                    values.cals4
                  );
                  validate &&
                    sendMeals(values.meal4, values.time4, values.cals4);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <InputMealSnack
              number={5}
              nameMeal="meal5"
              nameTime="time5"
              nameCals="cals5"
              valueMeal={values.meal5}
              valueCals={values.cals5}
              valueTime={values.time5}
              handleInputChange={handleInputChange}
            />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateMeals(
                    values.meal5,
                    values.time5,
                    values.cals5
                  );
                  validate &&
                    sendMeals(values.meal5, values.time5, values.cals5);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 7:
        return (
          <div>
            <InputMealSnack
              number={6}
              nameMeal="meal6"
              nameTime="time6"
              nameCals="cals6"
              valueMeal={values.meal6}
              valueCals={values.cals6}
              valueTime={values.time6}
              handleInputChange={handleInputChange}
            />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = validateMeals(
                    values.meal6,
                    values.time6,
                    values.cals6
                  );
                  validate &&
                    sendMeals(values.meal6, values.time6, values.cals6);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 8:
        return (
          <div>
            <InputTotalCalories value={`${sumCals(values)}`} />
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter + 1)}
              >
                Next
              </button>
            </div>

            <KeyPoints day={day} phase="priming" />
          </div>
        );

      case 9:
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn  colorPrimario text-white col-4 borderRound"
              type="submit"
            >
              SUBMIT
            </button>
          </div>
        );

      default:
        return "";
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="modal modalContainer d-block d-flex justify-content-center align-items-center px-1"
      >
        <div className="modal-dialog  mb-5  ">
          <div className="modal-content modalBody modalRounded ">
            <div className="modal-header border-0 d-flex flex-column">
              <h5 className="modal-title border-0 text-center fs-6">
                {name.toUpperCase()} {lastName.toUpperCase()}
              </h5>
              <h5 className="fs-6 mt-3">Priming phase</h5>

              <div className="d-flex  justify-content-center">
                <HexagonText title={day} subTitle="day" />
                <HexagonText title={`${month} ${numberDay}`} subTitle={year} />
              </div>
            </div>
            <div className="modal-body border-0   ">
              <div>{renderSwitch(counter)}</div>
            </div>
            <div className="modal-footer border-0  d-flex justify-content-center" />
          </div>
        </div>
      </form>
    </div>
  );
};
