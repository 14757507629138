import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/css/components/formLogin.css';
import { useForm } from '../hooks/useForm';
import { useWindowsSize } from '../hooks/useWindowsSize';
import { ArrowButton } from './ArrowButton';

export const FormLogin = ({ normalLogin }) => {
	const { width } = useWindowsSize();

	const initialState = {
		userName: '',
		password: ''
	};

	const { values, handleInputChange, reset } = useForm(initialState);

	const handleSubmit = (e) => {
		e.preventDefault();
		normalLogin(values.userName, values.password);
		console.log(`Username : ${values.userName} Password : ${values.password}`);
		reset();
	};

	return (
		<div className={`${width >= 1024 ? 'd-flex justify-content-center align-items-center' : ''}`}>
			<form
				onSubmit={handleSubmit}
				className={`${width >= 1024 ? ' d-flex justify-content-center align-items-center flex-column' : ''}`}
			>
				<div
					className={` mb-3 shadow-1  d-flex align-items-center justify-content-between mt-5 col-10 col-md-8 col-lg-9 col-xl-3  col-2   `}
				>
					<div className="w-90">
						<div className=" d-flex w-100 border-bottom flex-nowrap ">
							<span className="input-group-text bg-transparent border-0 ">
								<i className=" far fa-user  text-secondary  " />
							</span>

							<input
								type="email"
								className="form-control shadow-none p-3 w-75  border-0  "
								placeholder="Username"
								name="userName"
								value={values.userName}
								onChange={handleInputChange}
							/>
						</div>

						<div className=" d-flex w-100  flex-nowrap ">
							<span className="input-group-text bg-transparent border-0 ">
								<i className="fas fa-unlock-alt text-secondary  " />
							</span>

							<input
								type="password"
								className="form-control shadow-none p-3 w-75  border-0  "
								placeholder="Password"
								name="password"
								value={values.password}
								onChange={handleInputChange}
							/>
						</div>
					</div>

					<ArrowButton
						clase={`py-2 px-2  btnSubmit  rounded-circle p-3 text-white ${width >= 1024
							? 'position-relative ml-5'
							: 'position-absolute'}`}
					/>
				</div>

				<div className="form-text px-2">
					<span className="text-decoration-none text-secondary" href="#">
						<NavLink className="text-decoration-none text-muted " to="/register">
							Forgot your password ?
						</NavLink>
					</span>
				</div>
			</form>
		</div>
	);
};
