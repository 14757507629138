import React from 'react';
import { Link } from 'react-router-dom';
import arrow from '../assets/img/icons/arrow.png';
export const PreviousScreen = ({ path }) => {
	return (
		<Link to={path} className="d-flex py-3 text-decoration-none text-dark mx-2 fs-5 col-11 ">
			<div className="d-flex justify-content-start">
				<img className="col-1 ms-2" src={arrow} alt="" />
			</div>
		</Link>
	);
};
