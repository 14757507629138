import { db, collection, getDocs } from '../utils/configFirebase';
import axios from 'axios';

const getUsers = async () => {
    const usuarioCollection = collection(db, 'Users');

    const dataRes = await getDocs(usuarioCollection);
    const listUsuarios = dataRes.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    return listUsuarios;
}

const updateUser = async (data) => {
    try {
        const res = await axios.put('https://us-central1-ihealthe-app.cloudfunctions.net/Api/ihealthe/updateUser/', data);
        return await res.data;
    } catch (error) {
        console.log(error);
        return [];
    }
}




//exportar las acciones
export { getUsers, updateUser };