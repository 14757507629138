import React, { useState } from "react";
import isNumeric from "validator/lib/isNumeric";
import isEmpty from "validator/lib/isEmpty";

import { RoundInput } from "./RoundInput";
import { useForm } from "../hooks/useForm";
import { HexagonText } from "./HexagonText";
import isAlphanumeric from "validator/lib/isAlphanumeric";
import moment from "moment";
import { KeyPoints } from "./KeyPoints";
import { getNameUser } from "../helpers/getNameUser";

const initialState = {
  uponRising: false,
  MinutesBeforeMEal: false,
  morning: "",
  numberOfFastHours: "",
  numberOfSleepHours: "",
  meal1Time: "",
  meal1Protein: "",
  meal1Veggies: "",
  meal1Fruit: "",
  meal1WaterIntake: "",
  meal2Time: "",
  meal2Protein: "",
  meal2Veggies: "",
  meal2Fruit: "",
  meal2WaterIntake: "",
};

export const RealisingPhase = ({ setRealisingPhase, day, date, setmodal2 }) => {
  const year = moment(date).format("YYYY");
  const numberDay = moment(date).format("Do").replace("th", "");
  const month = moment(date).format("MMMM").substring(0, 3);
  const { name, lastName } = getNameUser();
  const { values, handleInputChange } = useForm(initialState);

  const [counter, setCounter] = useState(0);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    setRealisingPhase(false);
    setTimeout(() => {
      setmodal2(true);
    }, 2400);
  };

  const validateMeals = (time, protein, veggies, fruit, waterIntake) => {
    const validateTime = !isEmpty(time);
    const validateProtein = isAlphanumeric(protein);
    const validateVeggies = isAlphanumeric(veggies);
    const validateFruit = isAlphanumeric(fruit);
    const validateWater = isNumeric(waterIntake);
    if (
      validateFruit &&
      validateWater &&
      validateVeggies &&
      validateProtein &&
      validateTime
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderForm = () => {
    switch (counter) {
      case 0:
        return (
          <div>
            <div>
              <h2 className="fs-6 text-center">
                iHEALTHe Micro-Nutrient Release
              </h2>
              <h3 className="fs-6 text-center">Homeopathic Formula:</h3>
              <div className="d-flex justify-content-evenly ">
                <div className=" col-4 p-2 d-flex align-center-start justify-content-between">
                  <div className=" col-5 ">
                    <input
                      className="form-check-input    w-100 w-md-75 h-50 "
                      type="checkbox"
                      name="uponRising"
                      value="true"
                      onChange={(e) => {
                        handleInputChange({
                          target: {
                            name: e.target.name,
                            value: !values.uponRising,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className=" col-5 ">
                    <label
                      className="form-check-labe text-nowrap"
                      htmlFor="check1"
                    >
                      UPON <br /> RISING <br /> (3 SPRAYS)
                    </label>
                  </div>
                </div>

                <div className=" col-4 p-2 d-flex align-center-start justify-content-between">
                  <div className=" col-5 ">
                    <input
                      className="form-check-input   w-100 h-50  w-md-75  "
                      type="checkbox"
                      id="check2"
                      value="true"
                      name="MinutesBeforeMEal"
                      onChange={(e) => {
                        handleInputChange({
                          target: {
                            name: e.target.name,
                            value: !values.MinutesBeforeMEal,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className=" col-7  " style={{ fontSize: "0.9rem" }}>
                    <label
                      className="form-check-labe text-nowrap  "
                      htmlFor="check2"
                    >
                      30 MINUTES <br /> BEFORE MEAL 2 <br /> (3 SPRAYS)
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <RoundInput
              text="Morning weigh-in (kg) :"
              placeholder="Morning weigh-in  "
              value={values.morning}
              name="morning"
              handleInputChange={handleInputChange}
            />

            <div className=" d-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validate = isNumeric(values.morning);
                  validate && setCounter(counter + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );

      case 1:
        return (
          <div>
            <RoundInput
              text="Number of fast hours :"
              name="numberOfFastHours"
              type="number"
              placeholder="Number of fast hours"
              value={values.numberOfFastHours}
              handleInputChange={handleInputChange}
            />
            <div className="mt-4">
              <RoundInput
                text="Number of sleep hours :"
                name="numberOfSleepHours"
                type="number"
                placeholder="Number of sleep hours"
                value={values.numberOfSleepHours}
                handleInputChange={handleInputChange}
              />
            </div>

            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  const validateNumberOfFastHours = isNumeric(
                    values.numberOfFastHours
                  );
                  const validateNumberOfSleep = isNumeric(
                    values.numberOfSleepHours
                  );
                  if (validateNumberOfFastHours && validateNumberOfSleep) {
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );

      case 2: {
        return (
          <div>
            <p className="text-center text-muted">
              16 glasses of water = 4L (146 fl oz.)- we suggest taking <br /> 1
              glass per waking hour <br /> increase to up to 6oz of Box A & B if
              need.
            </p>
            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  setCounter(counter + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      }
      case 3:
        return (
          <div>
            <div className="col-12 mb-1 ">
              <h3 className="text-center fs-5">Meal 1</h3>
              <div className="m-2">
                <RoundInput
                  name="meal1Time"
                  type="time"
                  placeholder="Time"
                  value={values.meal1Time}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <RoundInput
                  type="text"
                  name="meal1Protein"
                  placeholder="Protein (4 oz)"
                  value={values.meal1Protein}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <RoundInput
                  type="text"
                  name="meal1Veggies"
                  placeholder="Veggies (4 oz)"
                  value={values.meal1Veggies}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <RoundInput
                  type="text"
                  name="meal1Fruit"
                  placeholder="Fruit (4 oz)"
                  value={values.meal1Fruit}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="m-2">
                <RoundInput
                  type="number"
                  name="meal1WaterIntake"
                  placeholder="Water Intake ( L )"
                  value={values.meal1WaterIntake}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
            <KeyPoints day={day} phase="releasing" />

            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (
                    validateMeals(
                      values.meal1Time,
                      values.meal1Protein,
                      values.meal1Veggies,
                      values.meal1Fruit,
                      values.meal1WaterIntake
                    )
                  ) {
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="col-12 mb-1 ">
            <h3 className="text-center fs-5">Meal 2</h3>
            <div className="m-2">
              <RoundInput
                name="meal2Time"
                type="time"
                placeholder="Time"
                value={values.meal2Time}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="m-2">
              <RoundInput
                type="text"
                name="meal2Protein"
                placeholder="Protein (4 oz)"
                value={values.meal2Protein}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="m-2">
              <RoundInput
                type="text"
                name="meal2Veggies"
                placeholder="Veggies (4 oz)"
                value={values.meal2Veggies}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="m-2">
              <RoundInput
                type="text"
                name="meal2Fruit"
                placeholder="Fruit (4 oz)"
                value={values.meal2Fruit}
                handleInputChange={handleInputChange}
              />
            </div>
            <div className="m-2">
              <RoundInput
                type="number"
                name="meal2WaterIntake"
                placeholder="Water Intake ( L )"
                value={values.meal2WaterIntake}
                handleInputChange={handleInputChange}
              />
            </div>
            <KeyPoints day={day} phase="releasing" />

            <div className=" d-flex justify-content-between mt-3  ">
              <button
                type="button"
                className="btn colorPrimario text-white col-4 borderRound"
                onClick={() => setCounter(counter - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="btn  colorPrimario text-white col-4 borderRound"
                onClick={() => {
                  if (
                    validateMeals(
                      values.meal2Time,
                      values.meal2Protein,
                      values.meal2Veggies,
                      values.meal2Fruit,
                      values.meal2WaterIntake
                    )
                  ) {
                    setCounter(counter + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        );

      case 5: {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <button
              type="submit"
              className="btn colorPrimario text-white col-4 borderRound"
            >
              Submit
            </button>
          </div>
        );
      }

      default:
        return "";
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="modal modalContainer d-block d-flex justify-content-center align-items-center px-1"
      >
        <div className="modal-dialog  mb-5  ">
          <div className="modal-content modalBody modalRounded " style={{overflow:'scroll',maxHeight:'90vh'}}>
            <div className="modal-header border-0 d-flex flex-column">
              <h5 className="modal-title border-0 text-center fs-6">
              {name.toUpperCase()} {lastName.toUpperCase()}
              </h5>
              <h5 className="fs-6 mt-3">Realising phase</h5>

              <div className="d-flex  justify-content-center">
                <HexagonText title={day} subTitle="day" />
                <HexagonText title={`${month} ${numberDay}`} subTitle={year} />
              </div>
            </div>
            <div className="modal-body border-0   ">{renderForm()}</div>
            <div className="modal-footer border-0  d-flex justify-content-center" />
          </div>
        </div>
      </form>
    </div>
  );
};
