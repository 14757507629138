import React from 'react';

export const InputTotalCalories = ({ value }) => {
	return (
		<div>
			<p>Total daily calories</p>
			<input
				type="text"
				name="total_calories"
				readOnly="readonly"
				value={value}
				className="borderRound form-control shadow"
				placeholder="Total daily calories"
			/>
		</div>
	);
};
