import React, { useState, useEffect } from "react";
import "../assets/css/views/faqs.css";

import { Header } from "../components/Header";

import { PreviousScreen } from "../components/PreviousScreen";
import { dataFaqs } from "../actions/actionFaqs";
//import homeVideo from '../assets/videos/home.mp4';

const FAQS = () => {
  const [data, setData] = useState([]);

  function filterDataForName(name) {
    //validar que el nombre no este vacio
    if (!(name === "")) {
      const newData = data.filter((item) =>
        item.name.toLowerCase().includes(name.toLowerCase())
      );

      setData(newData);
    } else {
      async function fetchData() {
        const res = await dataFaqs();
        setData(res);
        //await GuardarDataSubMenu();
        console.log(res);
      }

      fetchData();
    }
  }
  useEffect(() => {
    //cargar lo que viene en dataPriming async
    async function fetchData() {
      const res = await dataFaqs();
      setData(res);
    }

    fetchData();
  }, []);

  return (
    <>
      <Header  lateralMenu/>
      <div className="row position-relative">
        <div className="col-12">
          <PreviousScreen path="/mainMenu" />
        </div>
       <div className="d-flex justify-content-end position-absolute">
       <div className="col-6 col-md-5 col-xl-3">
          <input
            type="text"
            name="search"
            className="search form-control"
            id="search"
            placeholder="search.."
            onChange={(e) => filterDataForName(e.target.value)}
          />
        </div>
       </div>
      </div>
      <h1 className="text-center mt-3">FAQ'S</h1>
      <div className="accordion mt-4" id="accordionExample">
        {data.length > 0 ? (
          data.map((item, index) => {
            return (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#colapse${index}`}
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {item.name}
                  </button>
                </h2>
                <div
                  id={`colapse${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body d-flex justify-content-center">
                    <iframe
                      src={item.video}
                      title={item.name}
                      frameBorder="0"
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                 
                  </div>
                  <div className="d-flex justify-content-start ms-5">
                      <label htmlFor="">
                        <p className="text-center mt-3">{item.description}</p>
                      </label>
                    </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>Sin resultados...</p>
        )}
      </div>
    </>
  );
};

export default FAQS;
