import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
const update_Password = (newpassword, setstatusError, setsuccess) => {
  const auth = getAuth();

  const user = auth.currentUser;
  
  const newPassword = newpassword;

  updatePassword(user, newPassword)
    .then(() => {
      // Update successful.
    
      setsuccess(true);

      return true;
    })
    .catch((error) => {
      setstatusError({
        error: true,
        msj: error,
      });
      return;
    });
};

export const reauthenticateWithcredetials = (
  oldPassword,
  newPassword,
  setstatusError,
  setsuccess
) => {
  const auth = getAuth();

  const user = auth.currentUser;
  const typeLogin = user.providerData[0].providerId;
  if (typeLogin !== "password") {
    setstatusError({
      error: true,
      msj: "Error cannot change the password to accounts registered through google or facebook",
    });
    return false;
  }


  const credential = EmailAuthProvider.credential(user.email, oldPassword);

  reauthenticateWithCredential(user, credential)
    .then(async () => {
      // User re-authenticated.
     await  update_Password(newPassword, setstatusError, setsuccess);
     
      setstatusError({
        error: false,
        msj: "",
      });
      

     

      return true;
    })
    .catch((error) => {
     

      setstatusError({
        error: true,
        msj: error?.code,
      });

      return false;
    });
};
